import React, { useState, useEffect } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { getAllProductsList } from "../../api/http";
import envAddr from "../../api/env";
import { envAddrInSpace, inSpaceImg } from "../../api/env";
import styled from "styled-components";
import { Link } from "react-router-dom";

type dataParam = {
    title: string;
    sub_list: Array<listParam>
}

type listParam = {
    title: string;
    subtitle: string;
    new_mark: boolean;
    classname?: string;
    link: string;
    poster_img: string;
}
export type ProductsParam = {
    title: string;
    subtitle: string;
    order: number;
    data: Array<dataParam>;
}
const AllProductsList = () => {
    const [products, setProducts] = useState<ProductsParam>(),
          [order, setOrder] = useState(Number),
          [title, setTitle] = useState('');
    useEffect(() => {
        async function getInfo() {
            const dataInfo = await getAllProductsList();
            const {order, title} = dataInfo;
            setProducts(dataInfo);
            setOrder(order);
            setTitle(title);
        }
        getInfo();
    }, []);
    return (
        <React.Fragment>
            <Header curDataIndex={order} title={title} isIndex={true}/>
            <div className="main-wrap" id="mainIndex">
                <main className="main-content" role="main">
                    <AllProductsWrap>
                        <div className="info-wrap">
                            <header>
                                <h2  dangerouslySetInnerHTML={{__html:products?.title?products.title:""}}/>
                                <p>{products?.subtitle?products.subtitle:""}</p>
                            </header>
                        </div>

                        {
                            products?.data ? products.data.map((elem, index:number) => {
                                return <div key={index} className="product-content">
                                            <h3>{elem.title}</h3>
                                            <ul className="product-list-wrap">
                                                {
                                                    elem.sub_list.map((subInfo: listParam, subIndex: number) => {
                                                        return <li className={subInfo.classname} key={subIndex}>
                                                                    <ListLink to={subInfo.link} background-image={subInfo.poster_img}>
                                                                        <span dangerouslySetInnerHTML={{__html:subInfo.title}}/>
                                                                    </ListLink>
                                                                </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                            }) :""
                        }
                    </AllProductsWrap>
                </main>
                <Footer/>
            </div>
        </React.Fragment>
    )
}

export default AllProductsList;

const AllProductsWrap = styled.div`
    width: 100%;
    height: 100%;
    background: #fff;
    margin-top: 60px;
    padding-bottom: 150px;
    .info-wrap {
        padding-top: 150px;
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        h2 {
           i{
            font-size: 50px;
           }
        }
        p {
            font-size: 20px;
        }
    }
    > div.product-content{
        max-width: 1200px;
        margin: 150px auto 0 auto;
        h3 {
            font-size: 24px;
            text-align: left;
            border-bottom: 1px solid #000;
            padding-bottom: 10px;
        }
    }
    .product-list-wrap {
        width: 100%;
        overflow: hidden;
        li {
            float: left;
            width: 400px;
            height: calc(400px * 0.79614);
            text-align: center;
        }
        .center-bottom-box {
          width: 100%;
          text-align: center;
          height: 600px;
          margin-top: 10px;
        }
    }
    @media screen and (max-width: 768px) {
        padding-bottom: 100px;
        .info-wrap {
            padding-top: 50px;
            h2 {
                i{
                 font-size: 30px;
                }
             }
             p {
                 font-size: 18px;
             }
        }
        > div.product-content{
            width: calc(100vw - 48px);
            margin: 50px auto 0 auto;
            h3 {
                font-size: 20px;
                border-bottom: 1px solid #000;
                padding-bottom: 10px;
            }
        }
        .product-list-wrap {
            li {
                width: calc((100vw - 48px)/2);
                height: calc((100vw - 48px)/2 * 0.79614);
                span {
                    font-size: 12px;
                }
            }
        }
    }
`

const ListLink = styled(Link)`
    background-size: 100%;
    background-repeat: no-repeat;
    background-image:url(${(props:object) => props['background-image'] && props['background-image'] !=="" ? inSpaceImg.includes(props['background-image']) ? envAddrInSpace + props['background-image'] : envAddr + props['background-image']:""});
    width: 100%;
    height: 100%;
    color: #000;
    display: flex;
    align-items: flex-end;
    justify-content: center;
`