import React from "react";
import { RouteConfig } from "./router/routeConfig";
import { Route, Switch } from "react-router-dom";
import GlobalStyle from "./style/globalStyle";
import SideBar from './components/sidebar'
import { isDevices } from "./js/device";
const App = () => {
  const {isPc} = isDevices(navigator.userAgent)
  return (
    <React.Fragment>
      <GlobalStyle />
      <Switch>
        {RouteConfig.map((routerList, key) => {
          return (
            <Route
              exact
              path={routerList.path}
              component={routerList.component}
              key={key}
            />
          );
        })}
      </Switch>
      { isPc &&  <SideBar />}
    </React.Fragment>
  );
};

export default App;
