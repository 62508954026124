import React, { useState, useEffect } from "react";
import styled from "styled-components";
import envAddr from "../api/env";
import { envAddrInSpace } from "../api/env";
import { Link } from "react-router-dom";
import { variablesStyle } from "../style/variablesStyle";
import Innerbox from "./innerbox";
import { getNavList} from "../api/http";
import { ProductWrapper } from "./template/product-sale"
import { SubNav } from "./subnav"

interface HeaderProps {
	curDataIndex: number,
	title: string,
	isIndex ? : Boolean,
	sale ? : object,
	subnav ?: object
}

const Header = ( props:HeaderProps )=> {
	// 列表数据
	const [list, setList] = useState([]);
	// 当前导航顺序
	const curIndex = props.curDataIndex;
	// 点击后导航显示顺序
	const [dataIndex, setCurDataIndex] = useState(curIndex);
	
	// 是否显示产品销售信息
	const [isShowSaleInfo, setShowSaleInfo] = useState(false);
	
	const [isInner, setInnerDisplay] = useState(false),
		[isScroll, setIsScroll] = useState(false);

	// let changeIndex = (index: number) => {
	// 	setInnerDisplay(!isInner)
	// 	setCurDataIndex(index)
	// }
	const cutLang = (lan="zh-cn") => {
			if (lang !== lan) {
					sessionStorage.setItem("lang",lan)
					// history.go(0)
					// history.replace("/index")
					window.location.href="/index"
					window.location.reload()
			}
	}

	const scrollEvent = (event:any)=> {
		if (!isScroll) {
			let html = document.getElementsByTagName('html')[0],
				scrollY = (event.srcElement ? event.srcElement.documentElement.scrollTop : false)
				|| window.pageYOffset
				|| (event.srcElement ? event.srcElement.body.scrollTop : 0);
			
			if 	(scrollY < 0) scrollY = 0;
			
			if (scrollY > 0) {
				setIsScroll(true)
				html.classList.add('scroll');
			} else {
				setIsScroll(false)
				html.classList.remove('scroll');
				setShowSaleInfo(false);
				//window.removeEventListener('scroll', scrollEvent)
			}
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', scrollEvent, false);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	useEffect(() => {
        async function getInfo() {
            const navlist = await getNavList()
						setList(navlist['data'])
        }
		getInfo();
	}, [dataIndex])

	let isShowStyle = {
		display: !isInner ? "block" : "none"
	}
	const lang = window.sessionStorage.getItem("lang") ? sessionStorage.getItem("lang") : "zh";
	return (
		<React.Fragment>
				<HeaderWrap {...props} className = {`${props.isIndex  ? "headerWrap" : "headerWrap1"} ${isShowSaleInfo ? "show" : ""}`}>
					<header id="global-header" className ={ `${props.isIndex  ? "global-main-head" : "global-main-head1"} ${isScroll ? "scroll" : "" }`} style={isShowStyle}>
						<nav id="global-nav">
							<h1 className="logoWrap"><Link to="../index" className={ `${lang !== "en"  ? "logo" : "en-logo"}`} id="mainLogo"></Link></h1>
							<ul className="gloabal-nav-list" id="gloabalList">
								{
									list.map((elem, index) => {
										return<li data-index={ elem['order']} key={ index } >
											{
												!!elem['isLink']  ? <a href="/coffeerobot-t/index">{ elem['title'] }</a> :
												<a href="/" onClick={(e)=>{ e.preventDefault(); setInnerDisplay(!isInner); setCurDataIndex(elem['order']);}}>{elem['title']} </a>
											}
										</li>
									})
								}
							</ul>

							<div className="global-menu">
								<p className="lan"><span onClick={()=>{cutLang('zh-cn')}}>中文</span> | <span onClick={()=>{cutLang('en')}}>EN</span></p>
								<Link to="/" className="mobile-nav-icon" data-index={ props.curDataIndex } id="navBar" onClick = {(e)=>{ e.preventDefault(); setInnerDisplay(!isInner); setCurDataIndex(props.curDataIndex)}}>
									<span className="icon"></span>
								</Link>
							</div>
						</nav>
						
					</header>
					{props.sale && JSON.stringify(props.sale) !== '{}' ? <ProductWrapper {...props.sale}/> : "" }
					{
						!props.isIndex && !props.subnav?<h2 className="g-title">{ props.title }</h2>:""
					}
					{props.subnav ? <SubNav classname={props.sale?"":"no-sale"} {...props} title={ props.title }/> : "" }
				</HeaderWrap>
			
				<Innerbox  dataIndex = {dataIndex} isShow = {!isInner} changeShow = {(isShow: boolean) => {setInnerDisplay(isShow)}} changeIndex={(index: number) => {setCurDataIndex(index)}}/>
			
		</React.Fragment>
	)
}
export default Header;

export const HeaderWrap = styled.div`
	display            : block;
	margin             : 0;
	width              : 100%;
	font-size          : 17px;
	-webkit-user-select: none;
	-moz-user-select   : none;
	-ms-user-select    : none;
	user-select        : none;
	box-sizing: border-box;
	z-index: 999;
	position: relative;
	// -webkit-backdrop-filter: saturate(180%) blur(20px);
	// backdrop-filter: saturate(180%) blur(20px);
	transition: height ease .5s;
	backface-visibility: hidden;
	perspective: 1000;
	-webkit-overflow-scrolling : touch;
	.logo, .en-logo {
		display        : inline-block;
		width          : ${variablesStyle.pcLogoWidth};
		height         : ${variablesStyle.pcLogoHeight};
		background-repeat: no-repeat;
		background-size: 100%;
		vertical-align : middle;
		margin-top     : -6px; // 主要为了与文字对齐
	}
	.logo {
		background-image: url(${envAddrInSpace}images/common/web/logo.png);
	}
	.en-logo {
		background-image: url(${envAddrInSpace}images/common/web/logo.png);
	}
	
	.g-title {
		color    : #fff;
		font-size: 16px;
		cursor   : pointer;
		position: absolute;
		top: 60px;
		line-height: 52px;
		padding-left: 30px;
		box-sizing: border-box;
		background: #000;
		width: 100%;
		border-top: 1px solid rgba(255,255,255,.15);
	}

	.icon-arrow {
		position           : relative;
		vertical-align: middle;
		width              : 30px;
		height             : 23px;
		background-image   : url(${envAddr}images/common/icon_arrow.svg);
		background-repeat  : no-repeat;
		background-size    : cover;
		background-position: center;
		transition: all ease .1s;
		backface-visibility: hidden;
		perspective: 1000;
		display: : none;
	}
	
	.icon-arrow.active {
		display : inline-block;
	}

	.icon-arrow.active.transform {
		transform: rotateX(180deg)
		backface-visibility: hidden;
        perspective: 1000;
	}


	#global-nav{
		display: flex;
		width                     : 100%;
		position                  : relative;
		padding                   : 0 30px;
		text-align                : center;
		box-sizing : border-box;
		max-width: 100%;
		height: ${variablesStyle.headerHeight};
		line-height: ${variablesStyle.headerHeight};
		align-items: center;
		justify-content: space-between;
	}

	.gloabal-nav-list {
		li {
			display     : inline-block;
			margin-right: 52px;

			a {
				color      : rgba(255, 255, 255, 0.8);
				font-size  : 15px;
				font-weight: 400;
			}
		}

		li:last-child {
			margin-right: 0;
		}
	}
	.global-main-head {
		position: absolute;
		width: 100%;
	}

	.mobile-nav-icon {
		.icon {
			display: inline-block;
			width: 22px;
			height: 12px;
			background-image: url(${envAddr}images/common/icon_menu.png);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			position: relative;
			z-index: 22;
		}
	}
	.subnav {
		margin-top: ${(props:object) => props['sale'] ? '0':'60px'};
		position: relative;
		.g-title {
			top: 0;
		}
	}
	.subnav.no-sale {
		margin-top: 0;
	}

	.global-menu{
		display: flex;
		align-items: center;
    .lan {
        display: flex;
        flex-shrink: 0;
        color: rgba(255,255,255,.6);
				margin-right: 20px;
        span {
            font-size: 12px;
            color: #fff;
            cursor: pointer;
        }
        span:nth-child(1) {
            padding: 0 17px;
        }
        span:nth-child(2) {
            padding-left: 16px;
        }
        
    }
	}

	// pad
	@media screen and (max-width:768px) {
		.global-main-head .gloabal-nav-list {
			display: none;
		}
	}
	// mobile
	@media screen and (max-width:767px) {
		#global-nav {
			padding: 0 24px;
			height: auto;
			line-height: 52px;
			display: block;
			text-align: left;
			position: relative;
		}
		.global-menu {
			position: absolute;
			right: 24px;
			top: 20px;
			line-height: 0;
		} 
		.gloabal-nav-list li {
			margin-right: 20px;
		}

		.global-main-head1 {
			height: auto !important;
			.logoWrap {
				position: static;
				left: auto;
			}
			// .g-title {
			// 	border-top: 1px solid rgba(255,255,255,.15);
			// 	border-bottom: 1px solid rgba(255,255,255,.15);
			// }
		}
		.subnav {
			display: block;
		}
		.g-title, .subnav .g-title  {
			position: relative;
			top: 0;
			padding-left: 24px;
		}
		#global-header {
			height     : 52px;
			line-height: 52px;
		}

		#global-nav {
			padding: 0 24px;
		}

		.gloabal-nav-list {
			display: none;
		}

		.lan {
				span:nth-child(1) {
						padding: 0 17px 0 0;
				}
		}   
	}
`