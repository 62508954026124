import React from "react";
import styled from "styled-components";
import envAddr from "../../api/env";

type IconList = {
    title?: string;
    icon_url?: string;
    style?: {
        width?: string;
        height?: string;
        color?: string;
    }
}
type propParam = {
    icons_list: Array<IconList>;
    model?: string;
}
export default function Icon(props:propParam) {
    return (
        <IconWrap className="icon-list" length={props.icons_list.length}>
            {
                props.icons_list.map((iconItem, index) => (
                    <div key={index}>
                        <IconListWrap className="icon" icon_url = {iconItem.icon_url} width={iconItem.style && iconItem.style.width?iconItem.style.width:"80px"} height={iconItem.style && iconItem.style.height?iconItem.style.height:"80px"}/>
                        <IconTitle color={iconItem.style.color?iconItem.style.color:""} dangerouslySetInnerHTML={{__html: iconItem.title}}/>
                    </div>
                ))
            }
        </IconWrap>
    )
}
const IconTitle = styled.span<{color?:string}>`
    font-size: 12px;
    color: ${(props) => props.color ? props.color : "#fff"};
    padding-top: 6px;
    display: inline-block;
    text-align: center;
`
const IconListWrap = styled.figure<{icon_url?:string,width?:string,height?:string}>`
    width: ${(props) => props.width ? props.width: "80px"};
    height: ${(props) => props.height ? props.height : "80px"};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${(props) => props.icon_url ? envAddr + props.icon_url : ""});
    margin: 0 auto;
    margin-top:20px;
    @media screen and (max-width:768px) {
        width: calc(${(props) => props.width ? props.width: "80px"}/2);
        height: calc(${(props) => props.width ? props.height: "80px"}/2);
    }
`
const IconWrap = styled.div<{length?:number}>`
    display: flex;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    max-width: 500px;
    margin-top: 60px;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width:768px) {
        margin-top: 20px;
        width: 100%;
        padding: 0;
        justify-content: start;
    }
    >div {
        margin-right: 60px;
        text-align: center;
        max-width: 80px;
        @media screen and (max-width:768px) {
            margin-right: 24px;
            max-width: 72px;
        }
    }
    >div:nth-child(4) {
        margin-right: 0;
    }
    >div:nth-child(8) {
        margin-right: 0;
    }
`