import React, { useState, useEffect, createRef}from "react";
import styled from "styled-components";
import { videoAddr } from "../../api/env";
import envAddr from "../../api/env";
import { envAddrInSpace, inSpaceImg } from "../../api/env";
export type VideoParam = {
        isFull?: boolean | true;
        muted?: boolean | true;
        autoplay?: boolean | false;
        controls?: boolean | false;
        loop?: boolean | false;
        src: string;
        poster?: string;
}
export default function VideoFull(props:VideoParam) {
    const [isPlay, setIsPlay] = useState(false);
    const [playBtnName, setPlayBtnName] = useState("video-play");
    const playVideo = (e)=> {
        setIsPlay(!isPlay);
        const video = e.target.parentNode.children[0];

        video.play()
        addEventListenerVideo(video)
    }
    const addEventListenerVideo = (videoElem => {
        videoElem.addEventListener('play', function () { 
            setIsPlay(true);
            setPlayBtnName("video-pause");
        }, false);

        videoElem.addEventListener('ended', function () {
            setIsPlay(false);
            setPlayBtnName("video-play");
        }, false);
    })
    let videoObj:Object = {};
    const random = Math.random().toString(36).slice(2);
    videoObj[random] = createRef<HTMLVideoElement>();
    const videoRef = videoObj[random];
    useEffect(() =>{
        if(videoRef.current){
            videoRef.current.addEventListener('play', function () { //视频开始
                setIsPlay(true);
                setPlayBtnName("video-pause");
            }, false);
            videoRef.current.addEventListener('ended', function () { //视频结束
                setIsPlay(false);
                setPlayBtnName("video-play");
            }, false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isPlay, playBtnName])
    return (
        <VideoWrap isFull={props.isFull}>
             <video className="item-video"
                muted={props.muted}
                autoPlay={props.autoplay}
                controls={props.controls}
                preload="auto"
                ref={videoRef}
                x5-video-player-type="h5" x-webkit-airplay="allow" playsInline
                webkit-playsinline="true" poster={props.poster ? inSpaceImg.includes(props.poster) ? envAddrInSpace + props.poster : envAddr + props.poster : ""}>
                <source src={props.src ? videoAddr + props.src : ""}
                    type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"/>
            </video>
            <button className={`playback-control ${playBtnName}`} onClick={(e)=> {playVideo(e)}}></button>
        </VideoWrap>
    )
}

const VideoWrap = styled.div<{isFull:boolean}>`
    position: relative;
    width: ${(props) => props.isFull ? "100%" : "calc(100vw * .53333)"};
    height: ${(props) => props.isFull ? "calc(100vw * .5625)" : "calc(100vw * .53333 * .5625)"};
    overflow: hidden;
    position: ${(props) => props.isFull ? "absolute" : "relative"};
    top:0;
    left:0;
    margin: 0 auto;
    margin-top: ${(props) => props.isFull ? "0" : "100px"};
    @media screen and (max-width:768px) {
        // margin-top: 50px;
        width: 100%;
        height: ${(props) => props.isFull ? "calc(100vw * 1.77866)" : "calc(100vw * .5625)"};
    }
    video {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    button {
        background: transparent;
        border: none;
    }
    .playback-control {
        width: 40px;
        height: 40px;
        -webkit-tap-highlight-color: tansparent;
        background-repeat: no-repeat;
        display: block;
        background-size: contain;
        z-index: 222;
        position: absolute;
        bottom: 20px;
        left: 30px;
        @media screen and (max-width: 768px) {
            width: 60px;
            height: 60px;
            left: 50%;
            margin-left: -30px;
            top: 50%;
            margin-top: -30px;
        } 
    }
    .video-play {
        background-image: url(${envAddr}images/common/icon_play.png)
    }
    .video-pause {
        visibility: hidden;
        background-image: url(${envAddr}images/common/icon_stop.png)
    }
`