import React, { useState, useEffect } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import SubpageTemplate from "../../components/template/SubpageTemplate";
import { getKitchenRobotDetail } from "../../api/http";
const KitchenRobot = () => {
    const [list, setList] = useState([]);
    const [order, setOrder] = useState(Number);
    const [title, setTitle] = useState('');
    useEffect(() => {
        async function getInfo() {
            const dataInfo = await getKitchenRobotDetail();
            const {data, order, title} = dataInfo;
            setList(data);
            setOrder(order);
            setTitle(title);
        }
        getInfo();
    }, []);
    return (
        <React.Fragment>
            <Header curDataIndex={order} title={title}/>
            <div className="main-wrap" id="mainIndex">
                <main className="main-content" role="main">
                   <SubpageTemplate list={list} />
                </main>
                <Footer/>
            </div>
        </React.Fragment>
    )
}

export default KitchenRobot;