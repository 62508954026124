import React, { useRef } from 'react';
import styled from 'styled-components';
import { envAddrInSpace } from '../../api/env';
import Popover, { PopoverExpose } from '../Popover';

const imgPrefix = `${envAddrInSpace}images/common/web/sidebar`;

const lang =
  window.sessionStorage.getItem('lang') ||
  window.navigator.language.toLowerCase() ||
  'en';

const SideBar = () => {
  const AIPopover = useRef<PopoverExpose>();

  const SIDEBAR_ITEMS = [
    {
      label: lang === 'zh-cn' ? 'AI客服' : 'AI Service',
      icon: `${imgPrefix}/ai-customer.png`,
      click(e) {
        AIPopover.current.toggle(e);
      }
    },
    {
      label: lang === 'zh-cn' ? '邮箱联系' : 'Email Contact',
      icon: `${imgPrefix}/email.png`,
      click: async () => {
        window.location.href = 'mailto:sales@inSpace.cc';
      }
    },
    {
      label: lang === 'zh-cn' ? '合作洽谈' : 'Cooperate',
      icon: `${imgPrefix}/cooperation.png`,
      click() {
        window.open('https://ffmsqr.epub360.com.cn/v2/manage/book/e3jikr/');
      }
    }
  ];
  return (
    <>
      <SideBarWrap>
        {SIDEBAR_ITEMS.map((item, i) => (
          <SideBarItemWrap
            key={i}
            icon={item.icon}
            onClick={e => item.click(e)}>
            <div className='icon'>
              <i />
            </div>
            <span>{item.label}</span>
          </SideBarItemWrap>
        ))}
      </SideBarWrap>

      <Popover ref={AIPopover} width={350} height={600}>
        <iframe
          title='ai'
          frameBorder='0'
          height='100%'
          width='100%'
          src='https://link-ai.tech/web/iframe/b075017b-93c5-4c23-a17e-708107564c7a'></iframe>
      </Popover>
    </>
  );
};

const SideBarWrap = styled.div`
  position: fixed;
  right: 60px;
  bottom: 190px;
  z-index: 999;
`;

const SideBarItemWrap = styled.div<{ icon: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-bottom: 24px;
  > .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    background: rgba(170, 170, 170, 0.3);
    border-radius: 12px;
    margin-bottom: 8px;
    i {
      width: 36px;
      height: 36px;
      background: ${props => `url(${props.icon})`} no-repeat;
      background-size: 100% 100%;
    }
  }
  > span {
    font-size: 14px;
    color: #ffffff;
  }
`;

export default SideBar;
