
import { keyframes } from 'styled-components';

const FadeIn = keyframes`
    0% {
        opacity  : 0;
        transform: translateY(20px);
    }

    100% {
        opacity  : 1;
        transform: translateY(0);
        transform: scale(1);
    }
`

const FadeIn2 = keyframes`
    0% {
        opacity  : 0;
        transform: translateY(20px);
    }

    100% {
        opacity  : 1;
        transform: translateY(0);
        transform: scale(1);
    }
`


const Bouns = keyframes`
    0% {
        transform: scale(1.2);
    }

    100% {
       transform: scale(1);
    }
`


const ScaleSmall = keyframes`
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(1.2);
    }
`

const Rotate = keyframes`
    50% {
        transform: rotate(90deg);
    }

    100% {
        transform: rotate(0);
    }
`

// add 1014
const Loop = keyframes`
    0% {
        left: 0;
    }

    100% {
        left: 100%;
    }
`


export {
    FadeIn,
    FadeIn2,
    Bouns,
    ScaleSmall,
    Rotate,
    Loop
}