import React, { useState }from "react";
import styled from "styled-components";
import { videoAddr } from "../../api/env";
import envAddr from "../../api/env";
import {SliderTitle, SliderContent, CutSliderWrap} from "../slider/SliderCut";

export type VideoListParam = {
    title?: {
        txt?: string;
        color?: string;
    };
    subtitle?: {
        txt?: string;
        color?: string;
    };
    content?: {
        txt?: string;
        color?: string;
    };
    video_container: {
        muted?: boolean | true;
        autoplay?: boolean | false;
        controls?: boolean | false;
        loop?: boolean | false;
        src: string;
        poster?: string;
    };
}
export type VideoParam = {
    model: string;
    slider_list: Array<VideoListParam>;
}

type propParam = {
    list: Array<VideoListParam>;
    activeColor?: string;
    lang?: string;
}
export default function VideoCut(props:propParam) {
    const [startIndex, setStartIndex] = useState(0);
    const [isPlay, setIsPlay] = useState(false);
    const cutSlider = (e, index:number)=> {
        setIsPlay(false)
        setStartIndex(index)
        const video = e.target.parentNode.parentNode.getElementsByTagName('video');
        for (let i = 0; i < video.length; i++) {
            video[i].load();
            video[i].pause();
        }
        video[index].play()
        addEventListenerVideo(video[index])
    }
    const [playBtnName, setPlayBtnName] = useState("video-play");
    const playVideo = (e)=> {
        setIsPlay(!isPlay);
        const video = e.target.parentNode.children;

        for (let i = 0; i < video.length-1; i++) {
            video[i].load();
            video[i].pause();
        }
        video[startIndex].play()
        addEventListenerVideo(video[startIndex])
    }
    const addEventListenerVideo = (videoElem => {

        videoElem.addEventListener('play', function () { 
            setIsPlay(true);
            setPlayBtnName("video-pause");
        }, false);

        videoElem.addEventListener('ended', function () {
            setIsPlay(false);
            setPlayBtnName("video-play");
        }, false);
    })
    
    // useEffect(()=>{
    //     setPlayBtnName(!isPlay ? "video-play" : "video-pause");
    // },[isPlay, playBtnName])
    return (
        <CutVideoWrap activeColor={props.activeColor}>
            <VideoWrap>
                {
                    props.list.map((cutItem, index) => {
                        return <video key={index} className={`${startIndex === index ? 'active':""}`} muted={cutItem.video_container.muted}
                            autoPlay={cutItem.video_container.autoplay}
                            controls={cutItem.video_container.controls}
                            preload="auto"
                            x5-video-player-type="h5" x-webkit-airplay="allow" playsInline
                            webkit-playsinline="true" poster={cutItem.video_container.poster ? videoAddr + cutItem.video_container.poster : ""}>
                            <source src={cutItem.video_container.src ? videoAddr + cutItem.video_container.src : ""}
                                type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"/>
                        </video>
                    })
                }
                <button className={`playback-control ${playBtnName}`} onClick={(e)=> {playVideo(e)}}></button>
            </VideoWrap>
             
            <div className="cut-nav-list">
                {
                    props.list.map((cutItem, index) => {
                        return <SliderTitle lang={props.lang} color={cutItem.title.color} className={ startIndex === index ? 'active':""} onClick={(e)=> {cutSlider(e,index)}} key={index} dangerouslySetInnerHTML={{__html: cutItem.title.txt}}/>
                    })
                }
            </div>
                
            <div className="cut-nav-info">
                {
                    props.list.map((cutItem, index) => {
                        return <SliderContent lang={props.lang} color={cutItem.content.color} className={`cut-info ${startIndex === index ? 'active':""}`} key={index} dangerouslySetInnerHTML={{__html: cutItem.content.txt}}/>
                    })
                }
            </div>
            {/* <div className="cut-info-wrap">
                
            </div> */}
        </CutVideoWrap>
    )
}

const CutVideoWrap = styled(CutSliderWrap)<{activeColor?:string}>`
    a.active, p.active {
        color: ${(props) => props.activeColor !== "" ? props.activeColor : "#fff"}
    }
`
const VideoWrap = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vw * .53333 * .5625);
    overflow: hidden;
    @media screen and (max-width:768px) {
        height: calc(100vw * .5625);
    }
    video {
        display: none;
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    video.active {
        display: block;
    }
    button {
        background: transparent;
        border: none;
    }
    .playback-control {
        width: 40px;
        height: 40px;
        -webkit-tap-highlight-color: tansparent;
        background-repeat: no-repeat;
        display: block;
        background-size: contain;
        z-index: 222;
        position: absolute;
        bottom: 20px;
        left: 30px;
        @media screen and (max-width: 768px) {
            width: 60px;
            height: 60px;
            left: 50%;
            margin-left: -30px;
            top: 50%;
            margin-top: -30px;
        } 
    }
    .video-play {
        background-image: url(${envAddr}images/common/icon_play.png)
    }
    .video-pause {
        visibility: hidden;
        background-image: url(${envAddr}images/common/icon_stop.png)
    }
`