
import { api } from './api';
import Fetch from './Fetch';

// import { resAddr } from './env';

// 获取导航列表
export const getNavList = async () => {
    return await Fetch(api.publicApi.navlist)
}

// 获取底部导航列表
export const getFooterList = async () => {
    return  await Fetch(api.publicApi.footerlist)
}

// 获取首页信息
export const getIndexDetail = async () => {
    return  await Fetch(api.indexDetail)
}

// 获取insightPro信息
export const getInsightProDetail = async () => {
    return  await Fetch(api.insightPro.main)
}

// 获取insightPro场景信息
export const getInsightProScenario = async () => {
    return  await Fetch(api.insightPro.scenario)
}

// 获取insightPro参数信息
export const getInsightProSpec = async () => {
    return  await Fetch(api.insightPro.spec)
}

// 获取insightPro销售信息
export const getInsightProSaleInfo = async () => {
    return  await Fetch(api.insightPro.saleInfo )
}

// 获取insight信息
export const getInsightDetail = async () => {
    return  await Fetch(api.insight.main)
}

// 获取insight场景信息
export const getInsightScenario = async () => {
    return  await Fetch(api.insight.scenario)
}

// 获取insight参数信息
export const getInsightSpec = async () => {
    return  await Fetch(api.insight.spec)
}

// 获取insigh销售信息
export const getInsightSaleInfo = async () => {
    return  await Fetch(api.insight.saleInfo )
}

// 获取insight10信息20210927
export const getInsight10Detail = async () => {
    return  await Fetch(api.insight10.main)
}

// 获取insight10销售信息20210928
export const getInsight10SaleInfo = async () => {
    return  await Fetch(api.insight10.saleInfo )
}

// 获取insight10参数20211109
export const getInsight10Spec = async () => {
    return  await Fetch(api.insight10.spec )
}

// 获取insightos信息20210928
export const getInsightosDetail = async () => {
    return  await Fetch(api.insightos.main)
}

// 获取aiobox信息
export const getAioboxDetail = async () => {
    return  await Fetch(api.aiobox.main)
}

// 获取aiobox参数信息
export const getAioboxSpec = async () => {
    return  await Fetch(api.aiobox.spec)
}

// 获取aiobox销售信息
export const getAioboxSaleInfo = async () => {
    return  await Fetch(api.aiobox.saleInfo )
}

// 获取panel 智能开关面板信息
export const getPanelDetail = async () => {
    return  await Fetch(api.panel.main)
}

// 获取panel 智能开关面板参数信息
export const getPanelSpec = async () => {
    return  await Fetch(api.panel.spec)
}

// 获取panel 智能开关面板场景信息
export const getPanelScenario = async () => {
    return  await Fetch(api.panel.scenario)
}

// 获取panel 智能开关面板销售信息
export const getPanelSaleInfo = async () => {
    return  await Fetch(api.panel.saleInfo)
}

// 获取panel 智能情景开关信息
export const getSceneSwitchDetail = async () => {
    return  await Fetch(api.sceneSwitch.main)
}

// 获取panel 智能情景开关参数
export const getSceneSwitchSpec = async () => {
    return  await Fetch(api.sceneSwitch.spec)
}

// 获取panel 智能情景开关场景信息
export const getSceneSwitchScenario = async () => {
    return  await Fetch(api.sceneSwitch.scenario)
}

// 获取panel 智能情景开关板销售信息
export const getSceneSwitchSaleInfo = async () => {
    return  await Fetch(api.sceneSwitch.saleInfo)
}

// 获取smartDrive 智能驱动信息
export const getSmartDriveDetail = async () => {
    return  await Fetch(api.smartDrive.main)
}

// 获取smartDrive 智能驱动参数信息
export const getSmartDriveSpec = async () => {
    return  await Fetch(api.smartDrive.spec)
}


// 获取smartDrive 智能驱动销售信息
export const getSmartDriveSaleInfo = async () => {
    return  await Fetch(api.smartDrive.saleInfo)
}

// 获取smartCurtain 智能窗帘机信息
export const getSmartCurtainDetail = async () => {
    return  await Fetch(api.smartCurtain.main)
}

// 获取smartCurtain 智能窗帘机参数信息
export const getSmartCurtainSpec = async () => {
    return  await Fetch(api.smartCurtain.spec)
}


// 获取smartCurtain 智能窗帘机销售信息
export const getSmartCurtainSaleInfo = async () => {
    return  await Fetch(api.smartCurtain.saleInfo)
}

// 获取smartIncare 智能安防信息
export const getSmartIncareDetail = async () => {
    return  await Fetch(api.smartIncare.main)
}

// 获取smartDoor 智能门信息
export const getSmartDoorDetail = async () => {
    return  await Fetch(api.smartDoor.main)
}

// 获取smartIncare 智能门参数信息
export const getSmartDoorSpec = async () => {
    return  await Fetch(api.smartDoor.spec)
}

// 获取coffeerobot信息
export const getCoffeerobotDetail = async () => {
    return  await Fetch(api.coffeerobot.main)
}

// 获取coffeerobot场景信息
export const getCoffeerobotScenario = async () => {
    return  await Fetch(api.coffeerobot.scenario)
}

// 获取coffeerobot参数信息
export const getCoffeerobotSpec = async () => {
    return  await Fetch(api.coffeerobot.spec)
}

// 获取coffeerobotT信息
export const getCoffeerobotTDetail = async () => {
    return  await Fetch(api.coffeerobotT.main)
}

// 获取coffeerobotT参数信息
export const getCoffeerobotTSpec = async () => {
    return  await Fetch(api.coffeerobotT.spec)
}

// 获取coffeerobotT 销售信息
export const getCoffeerobotTSaleInfo = async () => {
    return  await Fetch(api.coffeerobotT.saleInfo)
}

// 获取kitchen-robot 智能星厨信息
export const getKitchenRobotDetail = async () => {
    return  await Fetch(api.kitchenrobot.main)
}

// 获取所有产品列表 20210601
export const getAllProductsList = async () => {
    return  await Fetch(api.allProductsList.main)
}

// 获取智能家居解决方案 20210616
export const getHomeSolutions = async () => {
    return await Fetch(api.homeSolutions.main)
}

// 获取高显射灯20210617
export const getSpotlightDetail = async () => {
    return  await Fetch(api.spotlight.main)
}

// 获取高显射灯参数20210617
export const getSpotlightSpec= async () => {
    return  await Fetch(api.spotlight.spec)
}

// 获取高显筒灯20210617
export const getDownlightDetail = async () => {
    return  await Fetch(api.downlight.main)
}

// 获取高显筒灯参数20210617
export const getDownlightSpec= async () => {
    return  await Fetch(api.downlight.spec)
}

// 获取影智介绍 20211108
export const getAboutDetail = async () => {
    return  await Fetch(api.about.main)
}

// inlife 智慧社区 20210928
export const getInLifeDetail = async () => {
    return  await Fetch(api.inlife.main)
}

//  加盟合作 20210929
export const getFranchiseCooperationDetail = async () => {
    return  await Fetch(api.franchiseCooperation.main)
}

//  单车 20211008
export const getBicycleDetail = async () => {
    return  await Fetch(api.bicycle.main)
}

//  灯带 20211012
export const getLightBeltDetail = async () => {
    return await Fetch(api.lightBelt.main)
}

//  燃气报警器 20211012
export const getGasAlarmDetail = async () => {
    return await Fetch(api.gasAlarm.main)
}

//  门窗传感器 20211012
export const getWindowSensorDetail = async () => {
    return await Fetch(api.windowSensor.main)
}

//  烟雾报警器 20211013
export const getSmokeAlarmDetail = async () => {
    return await Fetch(api.smokeAlarm.main)
}

//  水浸传感器 20211013
export const getWaterSensorDetail = async () => {
    return await Fetch(api.waterSensor.main)
}

//  人体传感器 20211013
export const getBodySensorDetail = async () => {
    return await Fetch(api.bodySensor.main)
}

//  inlight 20211018
export const getInlightDetail = async () => {
    return await Fetch(api.inlight.main)
}

//  insight-air 20211025
export const getInsightAirDetail = async () => {
    return await Fetch(api.insightAir.main)
}

//  insight-air saleinfo 20211025
export const getInsightAirSaleInfo = async () => {
    return await Fetch(api.insightAir.saleInfo)
}

//  insight-air spec 20211110
export const getInsightAirSpec = async () => {
    return await Fetch(api.insightAir.spec)
}

//  cooperationCase hongshuwan  20211112
export const getCooperationCaseHongshuwan = async () => {
    return await Fetch(api.cooperationCase.hongshuwan)
}

//  cooperationCase huanyutianxia  20211112
export const getCooperationCaseHuanyutianxia = async () => {
    return await Fetch(api.cooperationCase.huanyutianxia)
}

//  cooperationCase yuanyang  20211112
export const getCooperationCaseYuanyang = async () => {
    return await Fetch(api.cooperationCase.yuanyang)
}

//  cooperationCase jinghuijituan  20211116
export const getCooperationCaseJinghuijituan = async () => {
    return await Fetch(api.cooperationCase.jinghuijituan)
}

//  door-phone 20211117
export const getDoorPhoneDetail = async () => {
    return await Fetch(api.doorPhone.main)
}

//  door-phone spec 202111117
export const getDoorPhoneSpec = async () => {
    return await Fetch(api.doorPhone.spec)
}

// 招聘信息列表
export const getJobs =  ()=> Fetch(api.jobs.main)