import React from "react";
import styled from "styled-components";
import envAddr from "../../api/env";
import Header from "../../components/header";
import Footer from "../../components/footer";

const Store = () => {
	return (
		<React.Fragment>
            <Header curDataIndex={5} title="线下门店" />
			<StoreWrap className="main-wrap" id="mainIndex">
				<main className="main-content">
					<figure className="store-banner"></figure>
					<section className="list-content">
						<h2>线下零售店</h2>
						{/* <dl>
							<dt>合肥苏宁三里庵店</dt>
							<dd>地 址: 合肥市蜀山区长江西路304号鑫鹏大厦1-4层</dd>
							<dd>联系人: 徐伟</dd>
							<dd>电 话: 13951671687</dd>
						</dl> */}
						<dl>
							<dt>成都春熙路店</dt>
							<dd>地 址: 成都市锦江区红星路三段16号苏宁易购春熙路店一楼</dd>
							<dd>联系人: 徐伟</dd>
							<dd>电 话: 13951671687</dd>
						</dl>
						<dl>
							<dt>成都双楠店</dt>
							<dd>地 址: 成都市武侯区二环路西段置信路1号苏宁双楠店一楼</dd>
							<dd>联系人: 徐伟</dd>
							<dd>电 话: 13951671687</dd>
						</dl>
					</section>
				</main>
			</StoreWrap>
			<Footer/>
        </React.Fragment>
	)
}

export default Store;

const StoreWrap = styled.div`
	.store-banner {
		background-image:url(${envAddr}images/store/store_banner.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width:100%;
		height:600px;
		margin-top:60px;
	}
	.main-content {
		>section.list-content:first-child {
			padding-top:120px;
		}
		section.list-content:last-child {
			border:0;
		}
	}
	.list-content {
		margin: 60px auto 0 auto;
		padding: 0 22px 60px 22px;
		padding-bottom: 60px;
		border-bottom: 1px solid #2D2D2D;
		text-align:left;
		@include box-sizing;
		h2 {
			color: #fff;
		}
		h2,dt{
			font-size:26px;
			font-weight: bold;
			line-height: 37px;
		}
		dl {
			color:#A1A1A6;
			list-style:none;
			margin:0;
			padding:0;
			dt {
				margin:20px 0;
			}
			dd {
				font-size:14px;;
				line-height: 24px;
			}
		}
	}
	.addr-wrap{
		padding: 60px  22px;
		margin: 0 auto;
		color:#767676;
		font-size:14px;
		line-height: 24px;
		font-style:normal;
		a {
			color:#fff;
		}
	}
	@media screen and (max-width:768px) {
		.list-content {
			max-width:100%;
			h2,dt{
				font-size:18px;
				font-weight:normal;
			}
			padding-bottom:40px;
		}
		.main-content > section.list-content:first-child  {
			padding-top:40px;
		}
		.store-banner {
			height:400px;
			height:calc(100vw * 0.3125);
			margin-top:44px;
		}
	}
`
