
// 获取滑动方向
const getDirection = {
    // 获取的角度
    getSlideAngle: (dx: number, dy: number) => {
        return Math.atan2(dy, dx) * 180 / Math.PI;
    },

    //根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
    getSlideDirection: (startX: number, startY: number, endX: number, endY: number) => {
            // y轴距离
        let dy = startY - endY,
            
            // x轴距离
            dx = endX - startX,
            
            // 初始值
            result = 0,

            // 滑动后的角度
            angle = getDirection.getSlideAngle(dx, dy);

        //如果滑动距离太短
        if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
            return result;
        }

        if (angle >= -45 && angle < 45) {
            result = 4;
        } else if (angle >= 45 && angle < 135) {
            result = 1;
        } else if (angle >= -135 && angle < -45) {
            result = 2;
        } else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
            result = 3;
        }

        return result;
    }
}

export { getDirection };