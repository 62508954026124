import React, { useState, useEffect } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
// import { MainBlock } from "../components/template/main-block";
import SubpageTemplate from "../components/template/SubpageTemplate";
import { getIndexDetail } from "../api/http";
const Index = () => {
    const [list, setList] = useState([]);
    const [order, setOrder] = useState(Number);
    const [title, setTitle] = useState('');
    useEffect(() => {
        async function getInfo() {
            const dataInfo = await getIndexDetail();
            const {data, order, title} = dataInfo;
            setList(data);
            setOrder(order);
            setTitle(title);
        }
        getInfo();
    }, []);
    return (
        <React.Fragment>
            <Header curDataIndex={order} title={title} isIndex={true}/>
            <div className="main-wrap" id="mainIndex">
                <main className="main-content section-index" role="main">
                   {/* <MainBlock list={list} extralist={extralist} isIndex={true}/> */}
                   <SubpageTemplate list={list}/>
                </main>
                <Footer/>
            </div>
        </React.Fragment>
    )
}
export default Index;