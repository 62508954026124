// 判断设备
let isDevices = (userAgent?: string)=> {
    let ua = userAgent ? userAgent : "Chrome", //window.navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    // isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian && !isTablet;
    return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc
    };
};
// 返回对应设备数据
/*
    @objData json数据
    @name    获取的名称
    @child   子名称
    
*/
const device = (objData:object, name:string, child?:string)=> {
    const ua = window.navigator.userAgent,
          os = isDevices(ua);
    let isDevice = objData["devices"] && JSON.stringify(objData["devices"]) !== '{}',
        deviceWrap = isDevice && objData["devices"]["normal"][name] ? objData["devices"]["normal"][name] : "";
    
    if (os.isAndroid || os.isPhone) {
        // 手机
        deviceWrap = isDevice ? objData["devices"]["phone"][name] : "";
    } else if (os.isTablet) {
        // 平板
        deviceWrap = isDevice ? objData["devices"]["phone"][name] : "";
    } else if (os.isPc) {
        // pc
        deviceWrap = isDevice ? objData["devices"]["normal"][name] : "";
    }
    if (deviceWrap && name && child) {
        deviceWrap = deviceWrap[child];
    }
    
    return deviceWrap;
}

export { device, isDevices }