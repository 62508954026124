const vh = ( h:string ) => `
    height: ${h};
    line-height: ${h};
`;

/* @param
    $h => height
    $hPoint => height/width percent
*/
const fullScreen = (h:string, hPoint:string|number) => `
    width:100%;
    height: ${h};
    height: calc(100vw * ${hPoint});
`


export {vh , fullScreen}