import React from "react";
import styled from "styled-components";
import Common from "./Common";
import { device } from "../../js/device";
import { FormatSubTitle } from "../../Model/DeviceField";

export default function Subtitle(subTitleParam:FormatSubTitle) {
    const subTitleData = { 
        "color": device(subTitleParam, "subtitle_style", "color") ?  device(subTitleParam, "subtitle_style", "color") : "",
        "width": device(subTitleParam, "subtitle_style", "width") ?  device(subTitleParam, "subtitle_style", "width") : ""
    }
    // 优先取设备内部的subtitle，没有再取最外层subtitle
    const subTitleContent = device(subTitleParam,'subtitle_style','content')
    const subTitle = subTitleContent?.length ? subTitleContent: subTitleParam.subtitle
    // 自定义content样式
    const subTitleCustomStyle = device(subTitleParam, "subtitle_style", "custom_style")

    return (
        <SubtitleWrap className="subtitle-wrap" {...subTitleData} style={subTitleCustomStyle}>
            {
                (subTitle || []).map((subtitle, index) => (
                    <p key={index} dangerouslySetInnerHTML={{__html: subtitle}}></p>
                ))
            }
        </SubtitleWrap>
    )
}

const SubtitleWrap = styled(Common)<{ color?: string, width?: string }>`
    font-size: 22px;
    margin-top: 20px !important;
    @media screen and (max-width: 768px) {
        font-size: 14px;
        width: 100%;
        line-height: 22px;
    }
`