import React  from "react";
import styled from "styled-components";
import Common from "./Common";
import { device } from "../../js/device";
import { FormatContent } from "../../Model/DeviceField";

export default function Subtitle(contentParam:FormatContent) {
    const contentData = { 
        "color": device(contentParam, "content_style", "color") ?  device(contentParam, "content_style", "color") : "",
        "width": device(contentParam, "content_style", "width") ?  device(contentParam, "content_style", "width") : ""
    }
    // 自定义content样式
    const contentCustomStyle = device(contentParam, "content_style", "custom_style")
    // 优先取设备内部的content，没有再取最外层content
    const deviceContent = device(contentParam,'content_style','content')
    const content = deviceContent?.length ? deviceContent: contentParam.content
    return (
        <ContentWrap className="content-wrap" {...contentData} style={contentCustomStyle}>
            {
                content.map((content, index) => (
                    <p key={index} dangerouslySetInnerHTML={{__html: content}}></p>
                ))
            }
        </ContentWrap>
    )
}

const ContentWrap = styled(Common)<{ color?: string, width?: string }>`
    font-weight: 400;
    font-size: 22px;
    box-sizing: border-box;
    p {
        margin-top: 18px;
        line-height: 32px;
        font-size: 16px;
        max-width: ${(props) => props.width ? props.width : "100%"};
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        p {
            margin-top: 20px;
            line-height: 24px;
            font-size: 12px;
        }
    }
`