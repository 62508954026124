import React from "react";
import styled from "styled-components";

export type ContainerParams = {
    flex_classname?: string;
    block_bg?: string;
    is_full?: boolean;
    refs?: any,
    custom_style?: any
};

export default function Container(props:ContainerParams) {
    return (
        <ContainerBox ref={props.refs} {...props} style={props.custom_style}></ContainerBox>
    )
}

const layout = (name:string)=> {
    const [vertical,horizontal] = name.split("-");

    if (vertical === "top") return `padding-top: 4.1667%; text-align:${horizontal}; ${horizontal !== "center" ? "padding-" + horizontal + ":4.1667%" : ".icon-list{margin:3.13vw auto 0 auto}"}`;
    if (vertical === "center") return `align-items:center; text-align:${horizontal};${horizontal !== "center" ? "padding-" + horizontal + ":4.1667%;" : ""} ${horizontal === "right" ? "justify-content: flex-end;text-align:left" : ""}`;
    if (vertical === "bottom") return `text-align:${horizontal}; ${horizontal !== "center" ? "padding-" + horizontal + ":4.1667%;" : ""}  padding-top:0; align-items:flex-end;`;
}

const ContainerBox = styled.div< ContainerParams >`
    position: relative;
    width: 100%;
    height:  ${(props) => props.is_full ? "calc(100vw * .5625)" : "auto"};
    background-color: ${(props) => props.block_bg ? props.block_bg : "#000"};
    display: flex;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 222;
    color: #fff;
    ${(props) => props.flex_classname ? layout(props.flex_classname) : ""};
    padding-bottom: 4.1667%;
    box-sizing: border-box;
   
    >div {
        width: ${(props) => props.flex_classname.split("-")[1] === "right" ? "": "100%"};
        overflow-x: hidden;
    }
    .title-wrap, .content-wrap, .subtitle-wrap {
        ${(props) => props.flex_classname.split("-")[1] === "center" ? `margin: 0 auto`: ""}
    };
    
    @media screen and (max-width: 768px) {
        padding-top: 80px;
        width: 100%;
        height:  ${(props) => props.is_full ? "calc(100vw * 1.77866)" : "auto"};
        padding-left: 24px;
        padding-right: 24px;
        text-align: ${(props) => props.flex_classname.split("-")[1] !== "center" ? "left" : "center"};
        &:not(:last-child){
            padding-bottom: 0;
        }
    }
`