import React from "react";
import styled from "styled-components";
import envAddr from "../../api/env";
import { envAddrInSpace, inSpaceImg } from "../../api/env";
export const ProductWrapper = (props:any) => {
    // 格式转换 数字千分位
    const formatNumber = (num:number) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    const lang = window.sessionStorage.getItem('lang') || window.navigator.language.toLowerCase() || 'en';
    return (
        lang==="zh-cn"?
        <ProductWrap id="productInfo" className="product-wrapper">
            <div className="product-detail">
                <ProductFigure className="intr-pic" background-image={props['product_url']}></ProductFigure>
                <div className="product-info">
                    <h1>
                       <span dangerouslySetInnerHTML={{__html: props.title}}></span>
                       {
                           props.is_new ? <em>{props.new_txt}</em> : ""
                       }
                    </h1>
                    <p className="intr" dangerouslySetInnerHTML={{__html: props.intr}}></p>
                </div>
            </div>
            <div className="sale-wrapper">
                <div className="sale-info">
                    <p className="sale-title">{ props.price !== 0 ? props.sale_title : ""}</p>
                    <p className="price">
                        {
                            props.price !== 0 ? <strong>{props.symbol} {formatNumber(props.price)}</strong> : ""
                        }
                    </p>
                </div>
                <div className="btn-wrapper">
                    {/* { props.retail_txt !== "" ? <LinkBtn btn_link={props.retail_link} btn_txt={props.retail_txt} btnClass="btn-border"/>:"" } */}
                    { props.buy_txt !== "" ? <a href={props.buy_link} target="_blank" className="btn-solid" rel="noreferrer">{props.buy_txt}</a>:""}
                </div>
            </div>
        </ProductWrap>
        :<div></div>
    )
}
const ProductFigure = styled.figure`
    width: 162px;
    height: 162px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    background-image: url(${(props:object) => props['background-image'] && props['background-image'] !=="" ? inSpaceImg.includes(props['background-image']) ? envAddrInSpace + props['background-image'] : envAddr + props['background-image']:""});
    margin-right: 20px;
`
const ProductWrap = styled.div`
    position: relative;
    width: 100%;
    max-width: 900px;
    color: #fff;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 24px;
    .product-detail {
        display: flex;
        align-items: center;
        width: calc(68%);
        max-width: 520px;
    }
    h1 {
        vertical-align: middle;
        position: relative;
        z-index: 888;
        font-size: 18px;
    }
    .intr {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        color: rgba(255,255,255,.6);
        margin-top: 8px;
    }
    .btn-wrapper {
        a {
           padding: 4px 10px;
        }
    }
    .product-info {
        em {
            font-size: 18px;
            color: #6cfffa;
            font-style: normal;
            margin-left: 9px;
        }
    }
    .sale-wrapper {
        text-align: right;
        .sale-title {
            font-size: 12px;
        }
        .price {
            font-size: 30px;
            font-weight: 400;
            line-height: 42px;
            margin: 10px 0;
            strong {
                font-weight: normal;
            }
        }
    }
    @media screen and (max-width:767px) {
        width: 100%;
        padding: 0;
        .product-detail {
            display: none;
        }
        .sale-wrapper {
            background: #000;
            width: 100%;
            box-sizing: border-box;
            text-align: left;
            padding: 10px 24px 0 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .price {
                line-height: 30px;
                font-size: 22px;
                strong {
                    font-size: 22px;
                }
            }
        }
    }
`