import React, { useState }from "react";
import styled from "styled-components";
import envAddr from "../../api/env";
import { envAddrInSpace, inSpaceImg } from "../../api/env";
import { getDirection } from "../../js/public";
import { isDevices } from "../../js/device";
import { SliderListParam } from "./Slider";

type propParam = {
    list: Array<SliderListParam>
}
export default function SliderCard(props: propParam) {
    const ua = window.navigator.userAgent,
        os = isDevices(ua);
    // 随机生成id
    const randomId = Math.random().toString(36).slice(2);

    const initLength =  props.list.length,
        [curIndexSlide, setCurIndexSlide] = useState(0),
        [startDir, setStartDir] = useState({x:0,y:0}),
        [endDir, setEndDir] = useState({x:0,y:0}),
        [startTime, setStartTime] = useState(0);

    let timer = null;
    

    /*
        @index 当前轮播index
        @dir 方向 left right
    */
    const sliderEvent = (index: number, dir?: string) => {
        let slideWrap = document.getElementById(randomId),  // 父级
            slideListWrap = slideWrap.getElementsByClassName('slide')[0],   //  轮播容器
            slideList = slideWrap.getElementsByClassName('slide-item'),     // 轮播list
            slideArrowLeft = slideWrap.getElementsByClassName('slide-arrow-left')[0],     // 左箭头
            slideArrowRight = slideWrap.getElementsByClassName('slide-arrow-right')[0],     // 右箭头
            initWidth = slideList[0]['offsetWidth'],    // 每个子元素宽度
            clientWidth = document.body.clientWidth || document.body.clientWidth,    // 屏幕宽度
            distance = os.isPc ? 60 : 20,     // 间距
            initTotalWidth =  initWidth * initLength + initLength * distance,   // 总宽度
            showLength = os.isPc ? initLength - 3 : initLength - 1;
        
        let curIndex = index;
        const isShowArrow = () => {
            if (curIndex <=0) {
                slideArrowLeft.classList.add('hide')
            } else {
                slideArrowLeft.classList.remove('hide')
            }
            
            if (curIndex >= showLength) {
                slideArrowRight.classList.add('hide')
            } else {
                slideArrowRight.classList.remove('hide')
            }
        }

        if (initTotalWidth < clientWidth) {
            slideArrowLeft.classList.add('hide')
            slideArrowRight.classList.add('hide')
        }

        if (dir === "left" || dir === "right") {
        
            //slideListWrap['style'].transitionDuration = "200ms";
            
            clearTimeout(timer)

            if (dir === "left") {
                if (curIndexSlide > 0) {
                    setCurIndexSlide(curIndexSlide-1);
                    curIndex-=1;
                }
                isShowArrow();
            } else {
                if (curIndexSlide < showLength) {
                    setCurIndexSlide(curIndexSlide+1)
                    curIndex+=1;
                }
                isShowArrow();
            }
            if (os.isPc) {
                timer = setTimeout(()=> {
                    slideListWrap['style'].transform = `translate3d(${-(curIndex * initWidth + curIndex * distance) + 'px'},0px,0px)`;
                }, 200);

            } else {
                if (clientWidth > 1023) {
                    distance = 60; 
                    showLength = initLength -1
                } else {
                    distance = 20;
                    showLength = initLength -1
                }
                timer = setTimeout(()=> {
                    if (curIndex === showLength) {
                        slideListWrap['style'].transform = `translate3d(${-(curIndex * initWidth + curIndex * distance - clientWidth + initWidth + 48) + 'px'},0px,0px)`;
                    } else {
                        slideListWrap['style'].transform = `translate3d(${-(curIndex * initWidth + curIndex * distance) + 'px'},0px,0px)`;
                    }
                }, 100)
            }
            
            for (let l = 0; l < initLength; l++) {
                slideList[l].classList.remove('active');
                slideList[l]['style'].visibility = 'visible';
            }
            //slideList[curIndex].classList.add('active');
        }
    }

    const EventStart = (e:any) => {
        setStartDir({x: e.targetTouches[0].pageX, y: e.targetTouches[0].pageY}) 
    }

    const EventEnd = (e:any) => {
        setEndDir({x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY})
        
        let endTime = new Date().getTime(),
            direction = getDirection.getSlideDirection(startDir.x, startDir.y, endDir.x, endDir.y);
        
        if ((direction === 3 || direction === 4) && (endTime - startTime >200)) {
            setStartTime(endTime)
        
            if (direction === 3 && curIndexSlide < initLength) {
                sliderEvent(curIndexSlide, 'right')
            }

            if (direction === 4 && curIndexSlide > 0) {
                sliderEvent(curIndexSlide, 'left')
            }
        }
    }

    const EventClick = (e) => {
        e.currentTarget.className.indexOf("active") > -1 ? e.currentTarget.classList.remove('active') : e.currentTarget.classList.add("active");
    }

    return (
        <SliderCardWrap onTouchStart={(e) => { e.nativeEvent.stopImmediatePropagation(); EventStart(e)}} onTouchMove={(e)=>{EventEnd(e)}} id={randomId}>
            <div className="slide-list-wrap slide">
                {
                    props.list.map((slider, index)=> {
                        return <SliderItemWrap className="slide-item" key={index} img_url = {slider.img_url} onClick={(e) => {EventClick(e)}}>
                                <div className="slide-info">
                                    {slider.title ? <SliderTitle {...slider.title.color} dangerouslySetInnerHTML={{__html: slider.title.txt}}/> : ""}
                                    {
                                       slider.subtitle && slider.subtitle.txt !== "" ? <SliderSubTitle lang={slider.lang} {...slider.subtitle.color} dangerouslySetInnerHTML={{__html: slider.subtitle.txt}}/> : ""
                                    }
                                    {
                                       slider.content ? <SliderContent lang={slider.lang} color={slider.content.color} dangerouslySetInnerHTML={{__html: slider.content.txt}}/> : ""
                                    }
                                </div>
                            </SliderItemWrap>
                    })
                }
            </div>
            {
                os.isPc && initLength - 3 === 0 ? 
                ""
                :<div className="slide-arrow-wrap">
                    <button className="slide-arrow-left hide" onClick = {(e)=> {e.preventDefault(); sliderEvent(curIndexSlide, "left")}}></button>
                    <button className="slide-arrow-right"  onClick = {(e)=> {e.preventDefault(); sliderEvent(curIndexSlide, "right")}}></button>
                </div>
            }
           
        </SliderCardWrap>
    )
}

const SliderItemWrap = styled.div<{img_url:string}>`
    width : calc((100vw - 180px)/3.5);
    flex-shrink: 0;
    margin-left: 60px;
    height: calc((100vw - 180px)/3.5 * 1.2);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${(props) => props.img_url ? inSpaceImg.includes(props.img_url) ? envAddrInSpace + props.img_url : envAddr + props.img_url : ""});
    position: relative;
    display: -webkit-box;
    display: flex;
    align-items: end;
    align-items: flex-end;
    box-sizing: border-box;
    &:before {
        content: "";
        display: inline-block;
        width: 40px;
        height: 40px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(${envAddr}images/mobile/intr/icon_click_on.png);
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 22;
    }
    @media screen and (max-width: 768px) { 
        width: calc(100vw - 20px - 100vw/4);
        height: calc((100vw - 20px - 100vw/4) * 1.2);
        margin-left: 0;
        margin-right: 20px;
    }
    p {
        height: 0;
        display: none;
        overflow: hidden;
    }
`
const SliderTitle = styled.h3<{color?:string}>`
    font-size: 24px;
    color: ${(props) => props.color ? props.color : "#fff"};
    position: relative;
    z-index: 2;
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
`
const SliderSubTitle = styled.p<{color?:string, lang?: string}>`
    font-size: 15px;
    line-height: 28px;
    color: ${(props) => props.color ? props.color : "#fff"};
    position: relative;
    z-index: 2;
    margin-top: 20px;
    @media screen and (max-width: 768px) {
        font-size: 12px;
        line-height: 22px;
        margin-top: 20px;
    }
    ${(props) => props.lang === "en" ?`
        margin-top: 26px;
        @media screen and (max-width: 768px) {
            font-size: 10px;
            line-height: 18px;
            margin-top: 14px;
        }`
    : ""}
`

const SliderContent = styled(SliderSubTitle)``;

const SliderCardWrap = styled.div`
position: relative;
width: 100%;
.slide-list-wrap {
    display: -webkit-box;
    display: flex;
    justify-content: start;
    box-sizing: content-box;
    color: #fff;
    transition: ease .5s;
}

.slide-info {
    text-align: left;
    box-sizing: border-box;
    padding: calc((100vw - 180px)/3.5 * .08);
    @media screen and (max-width: 768px) {
        padding: 20px;
    }
}
.slide-arrow-left, .slide-arrow-right {
    width: 12px;
    height: 22px;
    display: inline-block;
    background-position: center;
    background-size: cover;
    position: absolute;
    z-index: 2;
}
.slide-arrow-wrap {
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: -22.5px;
}
.slide-arrow-wrap button {
    width: 45px;
    height: 45px;
    display: inline-block;
    position: absolute;
    cursor: pointer;
    border: none;
}
.slide-arrow-left {
    background-image: url(${envAddr}images/common/icon_previous.png);
    left: 4px;
}
.slide-arrow-right {
    background-image: url(${envAddr}images/common/icon_next.png);
    left: calc((100vw - 180px)/3.5 * 3 + 188px);
}
.slide-arrow-left.hide {
    visibility: hidden;
}
.slide-arrow-right.hide {
    visibility: hidden;
}
.slide-item.active {
    align-items: start;
    padding-top: 20px;
    p {
        height: auto;
        display: block;
    }
    &:after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.8);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
}
@media screen and (max-width: 768px) { 
    height: auto;
    min-height: auto;
    margin-bottom: 0;
    .slide-list-wrap {
        left: 0;
        .slide-list {
            height: 192px;
        }
    }
    .slide-arrow-wrap {
        top: 100px;
        button {
            display: none;
        }
    }
}
`