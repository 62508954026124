import React, { useState, useEffect } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import SubpageTemplate from "../../components/template/SubpageTemplate";
import { getInsightProDetail, getInsightProSaleInfo } from "../../api/http";
import "./index.css";
const InsightPro = () => {
    const [list, setList] = useState([]);
    const [order, setOrder] = useState(Number);
    const [title, setTitle] = useState('');
    const [productInfo, setProductInfo] = useState({});
    const [subNav, setSubNav] = useState([]);
    // const [footnotes, setFootnotes] = useState([])
    useEffect(() => {
        async function getInfo() {
            const dataInfo = await getInsightProDetail();
            const product_info = await getInsightProSaleInfo();
            const {data, order, title, sub_nav} = dataInfo;
            setList(data);
            setOrder(order);
            setTitle(title);
            setProductInfo(product_info.product_info);
            setSubNav(sub_nav)
            // setFootnotes(footnotes)
        }
        getInfo();
    }, []);
    return (
        <React.Fragment>
            <Header curDataIndex={order} title={title} sale={productInfo} subnav={subNav}/>
            <div className="main-wrap" id="mainIndex">
                <main className="main-content" role="main">
                   {/* <InnerBlock list={list} footnotes={footnotes}/> */}
                   <SubpageTemplate list={list}/>
                </main>
                <Footer/>
            </div>
        </React.Fragment>
    )
}

export default InsightPro;