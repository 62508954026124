import React from "react";
import styled from "styled-components";

type BtnParam = {
    btn_txt?: string;
    btn_link?: string;
    border_color?: string;
    color?: string;
}

const isSelf = (txt:string)=> {
    if (txt.indexOf("/") > -1 && txt.indexOf("/") === 0) return true;

    return false;
}
export const LinkBtn = (props:BtnParam) => {
    return (
        <LinkWrap  {...props} href={props.btn_link} target={isSelf(props.btn_link) ? "_self" : "_blank"}>
            {props.btn_txt}
        </LinkWrap>
    )
}

const LinkWrap = styled.a<{border_color?:string, color?:string}>`
    color: ${(props) => props.color ? props.color : "#fff"};
    font-size: 14px; 
    border: 1px solid ${(props) => props.border_color ? props.border_color : "#fff"};
    padding: 10px 16px;
    display: inline-block;
    border-radius: 18px;
    position: relative;
    z-index: 2;
    margin-top: 30px;
    @media screen and (max-width: 768px) {
        font-size: 12px;
        color: ${(props) => props.color ? props.color : "#fff"};
    }
`