import styled from "styled-components";

const Common = styled.div<{ color?: string, width?: string }>`
    color: ${(props) => props.color ? props.color : "#fff"};
    position: relative;
    z-index: 222;
    max-width: ${(props) => props.width ? props.width : "100%"};
    @media screen and (max-width: 768px) {
        width: 100%;
        color: ${(props) => props.color ? props.color : "#fff"};
        padding-left: 0;
        padding-right: 0;
    }
`

export default Common;