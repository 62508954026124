import inSpaceImg from '../js/inSpaceImg'
const env = process.env.NODE_ENV; 
const envProd = process.env.NODE_ENV as "production" | "prod";
// console.log(env)
// console.log(envProd)
let os = require('os'),
    resAddr = "https://img.knowin.com/knowin/data/",    // json数据地址
    //resAddr = "http://39.98.175.157:3002/data/",
    envAddr = "https://img.knowin.com/knowin/",         // 资源地址(主要图片)
    videoAddr = "https://dl.knowin.com/knowin/",       // 视频资源地址
    envAddrInSpace = "https://img.knowin.com/inspace/"
if (env === "development") {
    // 本地环境
    resAddr = "http://localhost:3000/data/";
    //resAddr = "http://localhost:3002/data/";
    if(os.hostname().indexOf('192') > -1) {
        resAddr = `http://${os.hostname()}:3000/data/`
    }
} else if (env === "test"){
    // 测试环境
    resAddr = "https://img.knowin.com/knowin/data/";
    //resAddr = "http://39.98.175.157:3002/data/";

} else {
    if(envProd === "production") {
        // 生产环境
        resAddr = "/data/";
        //resAddr = "http://localhost:3002/data/";
        envAddr = "https://img.knowin.com/knowin/";
        envAddrInSpace = "https://img.knowin.com/inspace/";
        videoAddr = "https://dl.knowin.com/knowin/";
    }
}

export default envAddr;
export { videoAddr, resAddr, envAddrInSpace, inSpaceImg };

// 请求数据路径
export const apiFile = envAddr + "data";

