import React, { useState }from "react";
import styled from "styled-components";
import envAddr ,{envAddrInSpace, inSpaceImg}from "../../api/env";
import { SliderListParam } from "./Slider";

type propParam = {
    list: Array<SliderListParam>;
    activeStyle?: string;
}
export default function SliderCut(props:propParam) {
    const [startIndex, setStartIndex] = useState(0);
    // 大图切换    
    let cutSlider = (index:number)=> {

        setStartIndex(index)
    }
    return (
        <CutSliderWrap color={props.activeStyle}>
            {
                props.list.map((cutItem, index) => {
                    return <Figure key={index} className={`${startIndex === index ? 'active':""}`} img_url = {cutItem.img_url}/>
                })
            }
            <div className="cut-info-wrap">
                <div className="cut-nav-list">
                    {
                        props.list.map((cutItem, index) => {
                            return <SliderTitle color={cutItem.title.color} className={ startIndex === index ? 'active':""} onClick={(e)=> {cutSlider(index)}} key={index} dangerouslySetInnerHTML={{__html: cutItem.title.txt}}/>
                        })
                    }
                </div>
                
                <div className="cut-nav-info">
                    {
                        props.list.map((cutItem, index) => {
                            return <SliderContent className={`cut-info ${startIndex === index ? 'active':""}`} key={index} dangerouslySetInnerHTML={{__html: cutItem.content.txt}}/>
                        })
                    }
                </div>
            </div>
        </CutSliderWrap>
    )
}

export const SliderTitle = styled.a<{color?:string}>`
    font-size: 16px;
    color: ${(props) => props.color ? props.color : "#fff"};
    @media screen and (max-width:768px) {
        font-size: 12px;
    }
`

export  const SliderContent = styled.p<{color?:string}>`
    font-size: 16px;
    color: #b4b4b4 !important;
    @media screen and (max-width:768px) {
        font-size: 12px;
    }
`;

export const Figure = styled.figure<{img_url?:string}>`
    width: 100%;
    height: calc(100vw * .53333 * .5625);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${(props) => props.img_url ? inSpaceImg.includes(props.img_url)?  envAddrInSpace + props.img_url   : envAddr + props.img_url : ""});
    @media screen and (max-width:768px) {
        height: calc(100vw * .5625);
    }
`
export const CutSliderWrap = styled.div<{color?:string}>`
    width: calc(100vw * .53333);
    margin: 0 auto;
    figure {
        display: none;
    }
    figure.active {
        display: block;
    }
    // .cut-info-wrap {
    //     display: block;
    //     width: 100%;
    //     perspective: 1000;
    //     backface-visibility: hidden;
    //     perspective: 1000;
    //     z-index:22;
    //     .cut-info {
    //         width: 100%;
    //         margin: 0 auto;
    //         display: none;
    //         text-align: left;
    //         padding-top: 40px;
    //     }
    //     .cut-info.active {
    //         display: block;
    //     }
    // }
    .cut-info {
        width: 100%;
        margin: 0 auto;
        display: none;
        text-align: left;
        padding-top: 40px;
    }
    .cut-info.active {
        display: block;
    }
    .cut-nav-list {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #b4b4b4;
        margin-top: 100px;
        padding-bottom: 10px;
        @media screen and (max-width:768px) {
            margin-top: 30px;
        }
        a {
            cursor: pointer;
            position: relative;
        }
        a.active {
            color: ${(props) => props.color ? props.color : "#fff"} !important;
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                border-bottom: 1px solid ${(props) => props.color ? props.color : "#fff"};
                bottom: -11px;
                left: 0;
            }
        }
    }
    @media screen and (max-width:768px) {
        width: 100%;
    }
`