import React from 'react';
import styled from "styled-components";
import envAddr from "../api/env";
const Spec = (props:any) => {
    return (
        <ParametersWrap className="parameters-wrap">
            <h2 className="parameter-title">{props.title}</h2>
            {
                props.list.map((elem:object, index:number) => {
                    return<div className="parameter-info" key={index}>
                        
                        <h3 className="parameter-list-title">{elem['title']}</h3>
                        {
                            elem['parameter'].map((parameterNote, index) => {
                                return parameterNote['parameter_img'] 
                                    ?   <div className="main-products-list" key={index}>
                                            <p className="title-line">{parameterNote['name']}</p>
                                            <div className="parameter-img-wrap"><ParameterImg background-images={parameterNote.parameter_img}></ParameterImg></div>
                                        </div>
                                    :   <div key={index}>
                                            <p className="title-line">{parameterNote['name']}</p>
                                           { parameterNote?.parameter_txt ? <span dangerouslySetInnerHTML={{__html: parameterNote.parameter_txt}}/>
                                            : <div>
                                                { parameterNote['parameter_list'] ? parameterNote['parameter_list'].map((list, index)=>{
                                                        return <p key={index} className="parameter-list">{list?.name ?<strong>{list['name']}</strong>:""}<span>{list['parameter_txt']}</span></p>
                                                    })
                                                    : ""
                                                }   
                                              </div>
                                            }
                                        </div>
                                
                            })
                        }
                    </div>
                })
            }
        </ParametersWrap>
    )
}

export { Spec };

const ParametersWrap = styled.div`
    color: #fff;
    position: relative;
    margin: 100px auto 60px;
    height: 100%;
    width: 100%;
    max-width: 1200px;
    padding: 0 24px;
    .parameter-title {
        font-size: 40px;
        margin-bottom: 100px;
    }
    .parameters-wrap > div.parameter-info:nth-of-type(n+1) {
        margin-top: 100px;
    }
    .parameter-info {
        position: relative;
        z-index: 2;
        > div {
            text-align: left;
            display: -webkit-flex;
            display: -o-flex;
            display: flex;
            -webkit-box-pack: flex-start;
            -webkit-justify-content: flex-start;
            -moz-box-pack: flex-start;
            -moz-justify-content: flex-start;
            -ms-flex-pack: flex-start;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 74px 0;
            border-bottom: 1px solid rgba(255,255,255,.15);
        }
        span {
            font-size: 16px;
            display: inline-block;
            color: #A1A1A6;
        }
    }
    .parameter-list-title {
        font-size: 26px;
        padding-top: 40px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(255,255,255,.6);
        text-align: left;
    }
    .main-products-list {
        height: calc(275px + 74px + 74px);
    }
    .title-line {
        font-size: 24px;
        margin-right: 15.14%;
        min-width: 100px;
    }
    .parameter-img-wrap {
        position: absolute;
        left: 50%;
        margin-left: -248px;
        width: 496px;
        height: 248px;
        figure {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            background-size: cover;
        }
    }
    .parameter-list {
        line-height: 32px;
        strong {
            min-width: 86px;
            display: inline-block;
        }
    }
`
const ParameterImg = styled.figure`
    background-image: url(${(props:object) => props['background-images'] ? envAddr + props['background-images'] : ""})
`