import { createGlobalStyle } from 'styled-components';
import { variablesStyle } from "./variablesStyle";
import envAddr from "../api/env";

const GlobalStyle = createGlobalStyle`


    // 微信浏览器兼容性
    #volume ::-webkit-media-controls-enclosure {
        display: none !important;
    }
    body.noscroll {
        height: 100%;
        overflow: hidden;
    }
    .main-wrap {
        width      : 100%;
        height: 100%;
        //max-width: 2560px;
        margin     : 0 auto;
        overflow   : hidden;
        position: relative;
        z-index: 222;
    }

    .main-wrap::-webkit-scrollbar {
        width: 0 !important
    }

    .main-wrap {
        -ms-overflow-style: none;
    }

    .main-wrap {
        overflow: -moz-scrollbars-none;
    }

    .main-content {
        position      : relative;
        // max-width: 2560px;
        margin        : 0 auto;
        // padding-top:55px;
        text-align    : center;
    }

    .wrapper {
        text-align : center;
        padding-top: 47px;
    }

    //按妞
    .btn {
        display      : inline-block;
        color        : #fff;
        font-size    : 16px;
        border       : 1px solid #fff;
        padding      : 10px 16px;
        border-radius: 20px;
        text-align      : center;
    }
    .btn-border, .btn-solid {
        border       : 1px solid ${variablesStyle.btnBgColor};
        min-width    : 70px;
        height       : 24px;
        line-height  : 24px;
        border-radius: 14px;
        font-size    : 12px;
        padding: 0 10px;
        color        : ${variablesStyle.btnBgColor};
    }
    
    .btn-solid {
        background-color: ${variablesStyle.btnBgColor};
        color: #fff;
    }
    .section-info{
        .btn {
            margin-top: 48px;
        }
        max-width: 1200px;
    }

    //公共字体大小
    .headline {
        font-size  : 56px;
        line-height: 1.07143;
        font-weight: 600;
        color      : #f5f5f7;
    }

    .section-intro {
        font-size     : ${variablesStyle.sectionIntroSize};
        letter-spacing: .009em;
        line-height   : 1.5;
        // font-weight: 600;
        color         : #a1a1a6;
    }

    .section-more {
        font-size: ${variablesStyle.linkSmallSize};
    }


    .tile-headline {
        font-size: 48px;
    }

    .top-left {
        padding-top: 7.8125%;
        padding-left: 7.8125%;
        text-align: left;
        justify-content: start;
    }
    .top-center {
        text-align: center;
        justify-content: center;
        padding-top: 7.8125%;
        // .subtitle {
        //     max-width: 800px;
        // }
    }
    .top-right {
        text-align: right;
        justify-content: flex-end;
        padding-top: 7.8125%;
    }
    .center-center {
        align-items: center;
        text-align: center;
        justify-content: center;
        .subtitle {
            max-width: 100%;
            text-align: center;
        }
    }
    .center-left {
        align-items: center;
        text-align: left;
        justify-content: start;
        padding-left: 7.8125%;
        // .section-info {
        //     max-width: 500px;
        // }
    }
    .center-right {
        align-items: center;
        text-align: left;
        justify-content: flex-end;
        padding-right: 7.8125%;
        .subtitle {
            text-align: left;
        }
    }
    .bottom-left {
        align-items: center;
        text-align: left;
        padding-bottom: 7.8125%;
    }
    .bottom-center {
        align-items: flex-end;
        justify-content: center;
        text-align: center;
        padding-bottom: 7.8125%;
    }
    .full-width {
        .subtitle {
            max-width: 100% !important; 
        }
    }
    .section-info.anima {
        opacity: 0;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 60, 0, 1);
    }
    @media screen and (min-width:767px) {
        .scroll .headerWrap {
            height: 60px;
        }
        .scroll .headerWrap1 {
            height: 113px;
        }
    }
    .scroll .headerWrap.show, .scroll .headerWrap1.show {
        height: auto;
        position: fixed;
    }

    // 滚动后缩起
    .scroll .headerWrap,  .scroll .headerWrap1{
        overflow: hidden;
        position: fixed;
        background: #000;
    }

    .scroll .headerWrap1 {
        .product-wrapper {
            height: 0;
            overflow: hidden;
        }
    }
    .scroll .headerWrap.show, .scroll .headerWrap1.show {
        height: auto;
        position: fixed;
    }
    .scroll .headerWrap1.show {
        .product-wrapper {
            height: auto;
        }
    }
    .global-main-head.scroll {
        background: #000;
    }

    .logo-coffeerobot-p em, .logo-coffeerobot-t em {
        visibility: hidden;
    }
    .green {
        color: #0FF;
    }
    .logo-coffeerobot-p, .logo-coffeerobot-t {
        display: inline-block;
        background-size: 100%;
        vertical-align: middle;
        background-repeat: no-repeat;
        width: 55px;
        height: 42px;
    }
    .logo-coffeerobot-p {
        background-image: url(${envAddr}images/coffeerobot/coffeerobot_title_icon.svg);
    }
    .logo-coffeerobot-t {
        background-image: url(${envAddr}images/coffeerobot-t/coffeerobot_t_title_icon.svg);
    }
    @media screen and (max-width:768px) {
        .sub-nav-wrap {
            top: 44px;
        }
        .sub-nav-list {
            //padding:0 24px;
            width: 100%;
            width: calc(100vw - 48px);
            box-sizing: border-box;
        }

        .sub-menu li {
            padding-left: 20px;
        }

        .sub-menu li:first-child {
            padding-left: 0;
        }

        .global-main-head a.mobile-nav-icon.on:after {
            width : 38px;
            height: 38px;
            right : -8px;
            top   : 6px;
        }
        .center-center .subtitle {
            max-width: 100%;
            text-align: left 
        }
        .logo-coffeerobot-p, .logo-coffeerobot-t {
            width: 39px;
            height: 30px;
        }
    }

    // 二级菜单end
    .list-wrap {
        background: #000;
    }

    .pagetitle {
        font-size  : ${variablesStyle.sectionTitleSize};
        line-height: 1.0625;
        font-weight: 700;
    }

    @media screen and (max-width:768px) {
        .gloabal-nav-list li {
            margin-right: 20px;
        }

        .global-main-head1 {
            .header-content {
                display: none;
            }
        }
    }

    @media screen and (max-width:768px) {

        .header-title {
            font-size: 36px;
        }
        .section-info {
            .btn {
                margin-top: 30px;
            }
        }
        .btn {
            font-size: 12px;
        }
        .section-intro {
            font-size        : 14px;
            // padding-bottom:40px;
        }

        .subhead {
            font-size: 16px;
        }

        .add-section-detail .detail-wrap,
        .add-section-detail1 .detail-wrap {
            padding: 0 22px !important;
        }
        
        .center-left {
            padding-left: 0;
            justify-content: center;
            align-items: start;
        }
        .center-right {
            padding-right: 0;
        }
    }
    .section-index .section-title {
        font-size: 60px;
        line-height: 82px;
    }
    @media screen and (max-width:767px) {
        .scroll .headerWrap{
            height: 52px;
            overflow: hidden;
        }
        .scroll .headerWrap1{
            min-height: 113px;
            max-height: 166px;
            overflow: hidden;
        }
        .section-index .section-title {
            font-size: 26px;
            line-height: 30px;
        }
    }
`
export default GlobalStyle;