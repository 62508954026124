// import {  } from './env';

export const api = {
    // 公共数据
    "publicApi" : {
        "navlist": "productList.json",
        "footerlist" : "footer.json"
    },

    // 首页数据
    "indexDetail" : "indexDetail.json",
    
    // insight-pro数据
    "insightPro": {
        "main" : "insight-pro/main.json",
        "spec" :  "insight-pro/spec.json",
        "scenario" :  "insight-pro/scenario.json",
        "saleInfo" :  "insight-pro/saleInfo.json"
    },

    // insight数据
    "insight": {
        "main" :  "insight/main.json",
        "spec" :  "insight/spec.json",
        "scenario" : "insight/scenario.json",
        "saleInfo" :  "insight/saleInfo.json"
    },

    // insight10数据 add20210927
    "insight10": {
        "main" :  "insight10/main.json",
        "saleInfo" :  "insight10/saleInfo.json",
        "spec" : "insight10/spec.json"
    },

    // insightos数据 add20210928
    "insightos": {
        "main" : "insightos/main.json"
    },

    // aiobox数据
    "aiobox": {
        "main" :  "aiobox/main.json",
        "spec" :  "aiobox/spec.json",
        "saleInfo" :  "aiobox/saleInfo.json"
    },

    // panel 开关面板数据
    "panel": {
        "main" :  "panel/main.json",
        "spec" :  "panel/spec.json",
        "scenario" :  "panel/scenario.json",
        "saleInfo" :  "panel/saleInfo.json"
    },

    // smartDrive 智能驱动数据
    "smartDrive": {
        "main" :  "smart-drive/main.json",
        "spec" :  "smart-drive/spec.json",
        "saleInfo" :  "smart-drive/saleInfo.json"
    },

    // smartCurtain 智能窗帘机
    "smartCurtain": {
        "main" :  "smart-curtain/main.json",
        "spec" :  "smart-curtain/spec.json",
        "saleInfo" :  "smart-curtain/saleInfo.json"
    },

    // smartIncare 智能安防
    "smartIncare": {
        "main" :  "smart-incare/main.json"
    },

    // smartDoor 智能门
    "smartDoor": {
        "main" :  "indoor-pro/main.json",
        "spec" :  "smart-door/spec.json",
    },

     // coffeerobot 咖啡机器人P系列
     "coffeerobot": {
        "main" :  "coffeerobot/main.json",
        "scenario" : "coffeerobot/scenario.json",
        "spec" :  "coffeerobot/spec.json"
    },

    // coffeerobot 咖啡机器人T系列
    "coffeerobotT": {
        "main" :  "coffeerobot-t/main.json",
        "spec" :  "coffeerobot-t/spec.json",
        "saleInfo" :  "coffeerobot-t/saleInfo.json"
    },

    // kitchenrobot 智能星厨
    "kitchenrobot": {
        "main" : "kitchen-robot/main.json"
    },

    // 所有产品列表 20210601
    "allProductsList": {
        "main" :  "all-products/main.json"
    },

    // 智能情景开关 20210608
    "sceneSwitch": {
        "main" :  "scene-switch/main.json",
        "scenario" : "scene-switch/scenario.json",
        "spec" :  "scene-switch/spec.json",
        "saleInfo" : "scene-switch/saleInfo.json"
    },

    // 智能家居解决方案 20210616
    "homeSolutions": {
        "main" :  "home-solutions/main.json"
    },

    // 高显射灯 20210617
    "spotlight": {
        "main" :  "spotlight/main.json",
        "spec" :  "spotlight/spec.json"
    },

    // 高显射灯 20210617
    "downlight": {
        "main" : "downlight/main.json",
        "spec" :  "downlight/spec.json"
    },

    // 关于我们 20211108
    "about": {
        "main" :  "about/main.json",
    },

    // inlife 智慧社区 add20210928
    "inlife": {
        "main" : "inlife/main.json"
    },

    // 加盟合作 add20210929
    "franchiseCooperation": {
        "main" :  "franchise-cooperation/main.json"
    },

    // 单车 add20211008
    "bicycle": {
        "main" :  "bicycle/main.json"
    },

    // 灯带 add20211012
    "lightBelt": {
        "main" :  "light-belt/main.json"
    },

    // 燃气报警器 add20211012
    "gasAlarm": {
        "main" :  "gas-alarm/main.json"
    },

    // 门窗传感器 add20211012
    "windowSensor": {
        "main" :  "window-sensor/main.json"
    },

    // 水浸传感器 add20211013
    "waterSensor": {
        "main" : "water-sensor/main.json"
    },

    // 烟雾报警器 add20211013
        "smokeAlarm": {
        "main" : "smoke-alarm/main.json"
    },

    // 人体传感器 add20211013
    "bodySensor": {
        "main" : "body-sensor/main.json"
    },

    // 人体传感器 add20211018
    "inlight": {
        "main" : "inlight/main.json"
    },

    // insight-air add20211025
    "insightAir": {
        "main" : "insight-air/main.json",
        "saleInfo" : "insight-air/saleInfo.json",
        "spec": "insight-air/spec.json"
    },

    //  add20211112  add1116
    "cooperationCase": {
        "hongshuwan" : "cooperation-case/hongshuwan.json",
        "huanyutianxia" : "cooperation-case/huanyutianxia.json",
        "yuanyang": "cooperation-case/yuanyang.json",
        "jinghuijituan": "cooperation-case/jinghuijituan.json"
    },

    // doorPhone 智能门口机 add20211117
    "doorPhone": {
        "main" : "door-phone/main.json",
        "saleInfo" : "door-phone/saleInfo.json",
        "spec": "door-phone/spec.json"
    },
    "jobs":{
        main:'jobs/main.json'
    }
}