/* 仅用来区分inspace的图片地址 */
const inSpaceImg = [
  'images/common/web/v_co_brands.png',
  'images/common/web/v_co_brands',
  'images/common/web/awards',
  'images/common/web/v_cooperative_partner',
  'images/common/web/v_cooperative_partner_en',
  'images/common/web/smart_home3.png',
  'images/common/web/home_solutions.jpg',
  'images/common/web/home_solutions',
  'images/common/web/logo.png',
  'images/common/web/worry_free_bag',
  'images/common/web/co_brands',
  'images/common/web/strategic_cooperation',
  'images/common/web/iterable_experience',
  'images/common/web/incare_product',
  'images/common/web/smoke_alarm.jpeg',
  'images/common/web/gas_alarm.jpeg',
  'images/common/web/smart_home3.png',
  'images/common/web/body_sensor.jpeg',
  'images/common/web/window_sensor.jpeg',
  'images/common/web/Bodypresence_sensor.jpeg',
  'images/common/web/card_smart_drive.jpg',
  'images/common/web/UI_interaction',
  'images/common/web/UI_interaction_en',
  'images/common/web/free_connect.png',
  'images/common/web/free_connect_en.png',
  'images/common/web/v_function',
  'images/common/web/v_function_en',
  'images/common/web/free_command.png',
  'images/common/web/free_command_en.png',
  'images/common/web/all_insight',
  'images/common/web/all_insight_en',
  'images/common/web/about/about5@2x',
  'images/common/web/about/about-logo@2x.png',
  'images/common/web/about/about7@2x',
  'images/common/web/about/about2@2x',
  'images/common/web/about/about1@2x',
  'images/common/web/about/about1@2x_en',
  'images/common/web/about/about3@2x',
  'images/common/web/about/about6@2x',
  'images/common/web/about/about6@2x_en',
  'images/common/web/about/about4@2x',

  'images/common/web/home-solutions/porch.png',
  'images/common/web/home-solutions/porch_en.png',
  'images/common/web/home-solutions/guest_restaurant.png',
  'images/common/web/home-solutions/guest_restaurant_en.png',
  'images/common/web/home-solutions/bedroom.png',
  'images/common/web/home-solutions/bedroom_en.png',
  'images/common/web/home-solutions/bathroom.png',
  'images/common/web/home-solutions/bathroom_en.png',

  'images/common/web/coffeeroxbot/coffeeroxbot@2x.png',
  'images/common/web/coffeeroxbot/product_insight@2x-1.png',
  'images/common/web/coffeeroxbot/product_insight@2x-2.png',
  'images/common/web/coffeeroxbot/product_insight@2x-3.png',
  'images/common/web/coffeeroxbot/product_insight@2x-4.png',
  'images/common/web/coffeeroxbot/product_insight@2x-5.png',
  'images/common/web/coffeeroxbot/product_insight@2x-6.png',
  'images/common/web/coffeeroxbot/product_insight@2x-7.png',
  'images/common/web/coffeeroxbot/product_insight@2x.png',
  'images/common/web/coffeeroxbot/coffeeroxbot@2x',
  'images/common/web/coffeeroxbot/coffeeroxbot1@2x',
  'images/common/web/coffeeroxbot/coffeeroxbot1@2x_en',
  'images/common/web/coffeeroxbot/coffeeroxbot2@2x',
  'images/common/web/coffeeroxbot/coffeeroxbot2@2x_en',
  'images/common/web/coffeeroxbot/coffeeroxbot3@2x',
  'images/common/web/coffeeroxbot/coffeeroxbot3@2x_en',
  'images/common/web/coffeeroxbot/coffeeroxbot4@2x',
  'images/common/web/coffeeroxbot/coffeeroxbot4@2x_en',
  'images/common/web/coffeeroxbot/coffeeroxbot5@2x',
  'images/common/web/coffeeroxbot/coffeeroxbot5@2x_en',
  'images/common/web/coffeeroxbot/coffeeroxbot6@2x',
  'images/common/web/coffeeroxbot/coffeeroxbot6@2x_en',
  'images/common/web/coffeeroxbot/coffeeroxbot7@2x',
  'images/common/web/coffeeroxbot/coffeeroxbot7@2x_en',
  'images/common/web/coffeeroxbot/all_products@2x',
  'images/common/web/coffeeroxbot/all_products@2x_en',
  'images/common/web/coffeeroxbot/report',
  'images/common/web/coffeeroxbot/report1',
  'images/common/web/coffeeroxbot/report2',
  'images/common/web/coffeeroxbot/report2_en',
  'images/common/web/coffeeroxbot/report3',
  'images/common/web/coffeeroxbot/report3_en',
  'images/common/web/coffeeroxbot/report4',
  'images/common/web/coffeeroxbot/report4_en',
  'images/common/web/coffeeroxbot/report5',
  'images/common/web/coffeeroxbot/report5_en',
  'images/common/web/coffeeroxbot/report6',
  'images/common/web/coffeeroxbot/report6_en',
  'images/common/web/coffeeroxbot/report7',
  'images/common/web/coffeeroxbot/CCTV-BTV.png',
  'images/common/web/coffeeroxbot/xbot-logo.png',

  'images/common/web/all-products/connection.png',
  'images/common/web/all-products/connection_en.png',
  'images/common/web/all-products/inTouchLiteA.jpg',
  'images/common/web/all-products/inLight/inLight4.jpg',
  'images/common/web/all-products/inLight/inLight5.jpg',
  'images/common/web/all-products/inLight/inLight3zigbee.jpg',
  'images/common/web/all-products/inLight/inLight3.jpg',
  'images/common/web/all-products/inLight/inLight6.jpg',
  'images/common/web/all-products/inLight/inLight7.jpg',
  'images/common/web/all-products/inLight/inLight1.jpg',
  'images/common/web/all-products/inLight/inLight2.jpg',
  'images/common/web/all-products/inCare/inCare.jpg',
  'images/common/web/all-products/inCare/inCare1.jpg',
  'images/common/web/all-products/inCare/inCare2.jpg',
  'images/common/web/all-products/inCare/inCare3.jpg',
  'images/common/web/all-products/inCare/inCare4.jpg',
  'images/common/web/all-products/inCare/inCare5.jpg',
  'images/common/web/all-products/inCare/inCare6.jpg',
  'images/common/web/all-products/inCare/inCare7.jpg',

  //////////////// 移动端
  'images/common/web/phone/home/home@2x',
  'images/common/web/phone/home/home@2x_en',
  'images/common/web/phone/home/home-strategy',
  'images/common/web/phone/home/home@2-1x',
  'images/common/web/phone/home/home@2-1x_en',

  'images/common/web/phone/home-solutions/home-solutions1',
  'images/common/web/phone/home-solutions/home-solutions2',
  'images/common/web/phone/home-solutions/home-solutions3',
  'images/common/web/phone/home-solutions/home-solutions4',
  'images/common/web/phone/home-solutions/home-solutions5',
  'images/common/web/phone/home-solutions/home-solutions5_en',
  'images/common/web/phone/home-solutions/home-solutions7',
  'images/common/web/phone/home-solutions/home-solutions',
  'images/common/web/phone/home-solutions/home-solutions6',

  'images/common/web/phone/cooperation-case/yuanyang/logo@2x.png',
  'images/common/web/phone/cooperation-case/huanyutianxia/logo@2x.png',
  'images/common/web/phone/cooperation-case/hongshuwan/logo2@2x.png',
  'images/common/web/phone/cooperation-case/jinghuijituan/logo.png',

  'images/common/web/phone/coffeeroxbot/1@2x',
  'images/common/web/phone/coffeeroxbot/1@2x_en',
  'images/common/web/phone/coffeeroxbot/2@2x',
  'images/common/web/phone/coffeeroxbot/2@2x_en',
  'images/common/web/phone/coffeeroxbot/3@2x',
  'images/common/web/phone/coffeeroxbot/3@2x_en',
  'images/common/web/phone/coffeeroxbot/4@2x',
  'images/common/web/phone/coffeeroxbot/4@2x_en',
  'images/common/web/phone/coffeeroxbot/5@2x',
  'images/common/web/phone/coffeeroxbot/5@2x_en',
  'images/common/web/phone/coffeeroxbot/7@2x',
  'images/common/web/phone/coffeeroxbot/8@2x',
  'images/common/web/phone/coffeeroxbot/9@2x',
  'images/common/web/phone/coffeeroxbot/9@2x_en',
  'images/common/web/phone/coffeeroxbot/11@2x',
  'images/common/web/phone/coffeeroxbot/11@2x_en',
  'images/common/web/phone/coffeeroxbot/12@2x',
  'images/common/web/phone/coffeeroxbot/12@2x_en',
  'images/common/web/phone/coffeeroxbot/report',
  'images/common/web/phone/coffeeroxbot/report1',
  'images/common/web/phone/coffeeroxbot/report2',
  'images/common/web/phone/coffeeroxbot/report2_en',
  'images/common/web/phone/coffeeroxbot/report3',
  'images/common/web/phone/coffeeroxbot/report3_en',
  'images/common/web/phone/coffeeroxbot/report4',
  'images/common/web/phone/coffeeroxbot/report4_en',
  'images/common/web/phone/coffeeroxbot/report5',
  'images/common/web/phone/coffeeroxbot/report5_en',
  'images/common/web/phone/coffeeroxbot/report6',
  'images/common/web/phone/coffeeroxbot/report6_en',
  'images/common/web/phone/coffeeroxbot/report7',

  'images/common/web/phone/inlight/all-inLight@2x',
  'images/common/web/phone/inlight/home_time@2x',

  'images/common/web/phone/smart-incare/smart-incare1',
  'images/common/web/phone/smart-incare/smart-incare2',
  'images/common/web/phone/smart-incare/smart-incare3',
  'images/common/web/phone/smart-incare/smart-incare4',
  'images/common/web/phone/smart-incare/smart-incare4_en',
  'images/common/web/phone/smart-incare/smart-incare5',
  'images/common/web/phone/smart-incare/smart-incare6',
  'images/common/web/phone/smart-incare/smart-incare7',
  'images/common/web/phone/smart-incare/smart-incare8',
  'images/common/web/phone/smart-incare/smart-incare9',

  'images/common/web/phone/about/adout6',
  'images/common/web/phone/about/adout8',
  'images/common/web/phone/about/tangmu',
  'images/common/web/phone/about/adout4',
  'images/common/web/phone/about/adoutInspace',
  'images/common/web/phone/about/adoutInspace_en',
  'images/common/web/phone/about/adout1@2x',
  'images/common/web/phone/about/adout2',
  'images/common/web/phone/about/adout3',

  'images/common/web/phone/insight13/home_time@2x',
  'images/common/web/phone/insight13/insight13-18',
  'images/common/web/phone/insight13/insight13-18_en',
  'images/common/web/phone/insight13/insight13-21',
  'images/common/web/phone/insight13/insight13-17',
  'images/common/web/phone/insight13/almighty_gateway@2x',
  'images/common/web/phone/insight13/almighty_gateway@2x_en',

  'images/common/web/phone/insight10/insight10@2x',
  'images/common/web/phone/insight10/insight10@2-1x',

  'images/common/web/phone/insight5/insight5-8',
  'images/common/web/phone/insight5/insight5-5',
  'images/common/web/phone/insight5/insight5-4',

  'images/common/web/phone/inTouch/inTouch2',
  'images/common/web/phone/inTouch/inTouch3',
  'images/common/web/phone/inTouch/inTouch4',

  'images/common/web/phone/scene-switch/scene_switch_operat@2x',
  'images/common/web/phone/scene-switch/scene_switch_compared@2x',
  'images/common/web/phone/scene-switch/scene_switch_process@2x',
  'images/common/web/phone/scene-switch/scene_switch_components@2x',

  'images/common/web/phone/air/all_insight@2x',
  'images/common/web/phone/air/18_martial_arts@2x',
  'images/common/web/phone/air/multimoding',
  'images/common/web/phone/air/insightos@2x',
];
export default inSpaceImg;
