import React, { useEffect, useState } from "react";
import styled from "styled-components";
import envAddr from "../../api/env";
import { envAddrInSpace, inSpaceImg } from "../../api/env";
import { FormatTitle } from "../../Model/DeviceField";
import { device } from "../../js/device";
import Common from "./Common";

export type formatTitleParams = {
    txt?: string;
    sign?: string;
    color?: string;
    width?: string;
    img?: string;
    img_width?: string;
    img_height?: string;
};

export type titleParams = {
    txt?: string;
    sign?: string;
    img?: string;
}

export default function Title(titleParam:FormatTitle) {
    const [titleData, setTitleData] = useState<formatTitleParams>({});
    const [txt, setTxt] = useState("");
    // const [sign, setSign] = useState("");
    const [imgTxt, setImgTxt] = useState("");
    useEffect(() => {
        // 优先取设备内部的title，没有再取最外层title
        const titleContent = device(titleParam,'title_style','content')
        const title = titleContent?.length ? titleContent: titleParam.title.txt

        setTitleData({
            "color": device(titleParam, "title_style", "color") ?  device(titleParam, "title_style", "color") : "",
            "width": device(titleParam, "title_style", "width") ?  device(titleParam, "title_style", "width") : "",
            "img_width": device(titleParam, "title_style", "img_width") ?  device(titleParam, "title_style", "img_width") : "",
            "img_height": device(titleParam, "title_style", "img_height") ?  device(titleParam, "title_style", "img_height") : ""
        })
        setTxt(title||'');
        setImgTxt(titleParam.title && titleParam.title.img ? titleParam.title.img : "");
        // setSign(titleParam.title && titleParam.title.sign ? titleParam.title.sign : "");
    }, [titleParam]);
    
    // 自定义title样式
    const titleCustomStyle = device(titleParam, "title_style", "custom_style")

    return (
        <TitleWrap className="title-wrap" {...titleData} style={titleCustomStyle}>
            {
                titleData.sign !== "" ?  <span>  </span> : ""
            }
            {
                imgTxt !== "" ?  <img src={ inSpaceImg.includes(imgTxt) ? envAddrInSpace + imgTxt : envAddr + imgTxt } width={titleData.img_width} height={titleData.img_height} alt="" /> : ""
            }
            {
               txt !== "" ?  <h1 className="section-title" dangerouslySetInnerHTML={{__html: txt}}/> : ""
            }
        </TitleWrap>
    )
}

const TitleWrap = styled(Common)<{ color?: string, width?: string,img_width?:string,img_height?:string }>`
    h1 {
        font-size: 2.5vw;
        line-height: 3.13vw;
    }
    @media screen and (max-width: 768px) {
        h1 {
            font-size: 22px;
            line-height: 30px;
        }
        width: 100%;
    }
`