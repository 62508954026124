import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SubNav = (props: any) => {
	/*
		@lineWrap 跟随线
		@listWrap 导航list
		@listData 初始数据
	*/
	//let listWrap = useRef(document.getElementById('sublist').getElementsByTagName('li'));
	
	let changeLinePos = (index?:number )=> {
		let lineWrap = document.getElementsByClassName('line')[0],
			listWrap = document.getElementById('sublist').getElementsByTagName('li'),
			listData = props.subnav,
			initIndex = index;
		
		// let getDistance = (index:number)=> {
		// 	let liIndex = index,
		// 		lineLeft = 0;

		// 	while(liIndex > 0) {
		// 		liIndex--;
		// 		return lineLeft += listWrap[liIndex].offsetWidth;
		// 	}

		// 	lineWrap['style']['width'] = listWrap[index].offsetWidth - 40 + 'px';
		// 	lineWrap['style']['left'] = lineLeft + 20 + 'px';
		// }

		if (typeof(initIndex) === "undefined") {
			listData.forEach((elem:object, index:number) => {
				if (elem['is_current']) {
					// curIndex = index;
					//getDistance(index)
					// return lineWrap['style']['width'] = listWrap[index].offsetWidth - 40 + 'px';
					let liIndex = index,
						lineLeft = 0;

					while(liIndex > 0) {
						liIndex--;
						lineLeft += listWrap[liIndex].offsetWidth;
					}
					lineWrap['style']['width'] = listWrap[index].offsetWidth - 40 + 'px';
					lineWrap['style']['left'] = lineLeft + 20 + 'px';
				}
			})
		} else {
			let liIndex = index,
				lineLeft = 0;

			while(liIndex > 0) {
				liIndex--;
				lineLeft += listWrap[liIndex].offsetWidth;
			}

			lineWrap['style']['width'] = listWrap[index].offsetWidth - 40 + 'px';
			lineWrap['style']['left'] = lineLeft + 20 + 'px';
		}
		// if (listWrap && listWrap.length > 0) {
		// 	getDistance(curIndex)
		// }
	}

	useEffect(() => {
		changeLinePos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

	return (
		<SubNavWrap className={`subnav ${props.classname ? props.classname:""}`} title={props.title}>
			<h2 className="g-title">{ props.title }</h2>
			<div className="subwrap">
				<ul className="sublist" id="sublist">
					{
						props.subnav.map((elem:any, index:any) => {
							return <li className ={ elem['is_current'] ? "on" : ""} key={index} onMouseEnter = {(e) => {changeLinePos(index)}} onClick = {(e) => {changeLinePos(index)}}>
										<Link to={elem.link}>{elem.title}</Link>
								   </li>
						})
					}
				</ul>
				<div className="line"></div>
			</div>
		</SubNavWrap>
	)
}
const SubNavWrap = styled.nav`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	border-top: 1px solid rgba(255,255,255,.15);
	.subwrap {
		position: relative;
	}
	.sublist {
		li {
			display: inline-block;
			padding: 16px 20px;
			a {
				font-size: 14px;
				font-weight: 500;
				color: rgba(255, 255, 255, .6);
			}
			a:hover {
				color: rgba(255, 255, 255, 1);
			}
		}
		li.on {
			a {
				color: rgba(255, 255, 255, .4);
			}
		}
		li.on {
			a:hover {
				rgba(255, 255, 255, .4)
			}
		}
	}
	.line {
		position: absolute;
		height: 2px;
		background-color: #00a4fe;
		top: -1px;
		left: 20px;
		transition: all ease .4s;
	}

	@media screen and (max-width:768px) { 
		justify-content: start;
		align-items: center;
		background: #000;
	}
`