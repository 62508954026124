import styled from "styled-components";
import envAddr from "../../api/env";
export const Award = styled.div<{img_width:string, img_height:string, img_url:string}>`
    display: block;
    position: absolute;
    bottom: 20px;
    z-index: 2;
    width: ${(props) => props.img_width ? props.img_width  : ""} !important;
    height: ${(props) => props.img_height ? props.img_height : ""};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${(props) => props.img_url ? envAddr + props.img_url : ""});
    left: 50%;
    transform: translate(-50%);
    backface-visibility: hidden;
    perspective: 1000;
`