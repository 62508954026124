import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import envAddr from '../../api/env';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { getJobs } from '../../api/http';

const Jobs = () => {
  const lang =
    window.sessionStorage.getItem('lang') ||
    window.navigator.language.toLowerCase() ||
    'en';

  const [jobs, setJobs] = useState([]);
  const [title, setTitle] = useState('');
  useEffect(() => {
    const fetchRequest = async () => {
      const { title, data } = await getJobs();
      setTitle(title);
      setJobs(data);
    };
    fetchRequest();
  }, []);

  return (
    <React.Fragment>
      <Header curDataIndex={4} title={title} />
      <div className='main-wrap' id='mainIndex'>
        <JobsWrap>
          {jobs.map((job, jobIndex) => (
            <Fragment key={jobIndex}>
              <section
                className='list-contents'
                style={{
                  margin: '60px auto 0 auto',
                  padding: '0 22px 60px 22px',
                  paddingBottom: '60px',
                  borderBottom: '1px solid #2d2d2d',
                  textAlign: 'left'
                }}>
                <h2>{job.title}</h2>
                <dl>
                  <dt>
                    {lang === 'zh-cn' ? '岗位职责' : 'Job Responsibilities'}：
                  </dt>
                  {(job.duties || []).map((duty, dutyIndex) => (
                    <dd key={dutyIndex}>{duty}</dd>
                  ))}
                </dl>
                <dl>
                  <dt>
                    {lang === 'zh-cn' ? '岗位要求' : 'Job Requirements'}：
                  </dt>
                  {(job.requirements || []).map((requirement, rIndex) => (
                    <dd key={rIndex}>{requirement}</dd>
                  ))}
                </dl>
              </section>
            </Fragment>
          ))}

          <div className='addr-wrap flex-betwwen'>
            <address>
              {lang === 'zh-cn'
                ? '投递简历或想要了解更多招聘信息，欢迎联系inSpace HR团队 '
                : 'To submit your resume or learn more about recruitment information, please contact inSpace HR team at '}

              <a href='mailto:HR@inSpace.cc'>HR@inSpace.cc</a>
            </address>
          </div>
        </JobsWrap>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Jobs;

const JobsWrap = styled.div`
  max-width: 980px;
  margin: 120px auto 0 auto;
  .know-join {
    background: url(${envAddr}images/join-us-bg.jpg) center no-repeat;
    background-size: cover;
    height: 600px;
    text-align: center;
    margin-top: 92px;
  }

  .word-wrap {
    img {
      vertical-align: middle;
    }
    @include transform(translateY(-50%));
    position: relative;
    top: 50%;
    img:nth-child(2) {
      width: 0;
    }
  }
  .job-nav-bg {
    background: #000;
  }

  .main-content {
    > section.list-contents:first-child {
      padding-top: 120px;
    }
    section.list-contents:last-child {
      border: 0;
    }
  }
  .list-contents {
    margin: 60px auto 0 auto;
    padding: 0 22px 60px 22px;
    padding-bottom: 60px;
    border-bottom: 1px solid #2d2d2d;
    text-align: left;
    @include box-sizing;
    h2 {
      color: #fff;
    }
    h2,
    dt {
      font-size: 26px;
      font-weight: bold;
      line-height: 37px;
    }
    dl {
      color: #a1a1a6;
      dt {
        margin: 20px 0;
      }
      dd {
        font-size: 14px;
        line-height: 24px;
        &::before {
          content: '';
          width: 3px;
          height: 3px;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          line-height: 24px;
          font-size: 14px;
          background: rgba(219, 219, 219, 0.3);
          margin: 0 4px;
        }
      }
    }
  }
  .addr-wrap {
    padding: 60px 22px;
    margin: 0 auto;
    color: #767676;
    font-size: 14px;
    line-height: 24px;
    font-style: normal;
    display: flex;
    justify-content: space-between;
    a {
      color: #fff;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    .list-contents {
      h2,
      dt {
        font-size: 18px;
        font-weight: normal;
      }
      padding-bottom: 40px;
    }
    .addr-wrap {
      max-width: 100%;
      display: block;
      text-align: center;
    }
    .list-contents dl dt {
      margin: 10px 0;
    }
    .top-link {
      margin-top: 20px;
      margin-left: 84%;
    }
    .main-content > section.list-contents:first-child {
      padding-top: 40px;
    }
  }
`;
