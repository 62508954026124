import React from "react";
import SliderCard from "./SliderCard";
import SliderCut from "./SliderCut";

export type SliderListParam = {
    title?: {
        txt?: string;
        color?: string;
    };
    subtitle?: {
        txt?: string;
        color?: string;
    };
    content?: {
        txt?: string;
        color?: string;
    };
    img_url?: string;
    active_color?: string;
    lang?: string;
}
export type SliderParam = {
    model: string;
    slider_list: Array<SliderListParam>;
    active_color?: string;
    lang?: string;
}
export  default function Slider (props:SliderParam) {
    return (
        <React.Fragment>
            {
                props.model === "slider-card"
                ? <SliderCard list={props.slider_list}/>
                : <SliderCut list={props.slider_list} activeStyle={props.active_color}/>
            }
        </React.Fragment>
    )
}