import React,{useState} from "react";
import styled from "styled-components";
import envAddr from "../api/env";
const ScenarioWrap = (props:any) => {
    let len = props['list'].length;
    const [curIndex, setCurIndex] = useState(0);
    return(
        <ScenarioWrapStyle>
            <div className="arrow-wrap">
                <button className="arrow-left" onClick={(e) => {e.preventDefault(); curIndex > 0 ? setCurIndex(curIndex-1):setCurIndex(0)}}>&nbsp;</button>
                <button  className="arrow-right" onClick={(e) => {e.preventDefault(); curIndex < len-1 ? setCurIndex(curIndex+1):setCurIndex(len-1)}}>&nbsp;</button>
            </div>
            <UlWrap>
                {
                    props['list'].map((elem:object, index:number) => {
                        return<LiList className = {index === curIndex ? "list-item active" : "list-item"} background-image={elem['banner_url']} color={elem['title_color']} key={index}>
                            <p>{elem['title']}</p>
                        </LiList>
                    })
                }
            </UlWrap>
            <DotWrap className="dot-wrap">
                {
                    props['list'].map((elem:object, index:number) => {
                        return<span className={index === curIndex ? "active": ""} key={index}></span>
                    })
                }
            </DotWrap>
        </ScenarioWrapStyle>
    )
}

export { ScenarioWrap };


const UlWrap = styled.ul`
    position: relative;
    width: 100%;
    height: 100%;
    li.active {
        opacity: 1;
    }
`
const LiList = styled.li`
    position: absolute;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    transition: opacity 1s ease;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${(props:object) => props['background-image'] ? envAddr + props['background-image'] : ""}); 
    opacity: 0;
    p {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        backface-visibility: hidden;
        perspective: 1000;
        color: ${(props:object) => props['color'] ? props['color'] : "#fff"}
    }
    @media screen and (max-width:768px) { 
        opacity: 1 !important;
        position: relative;
        height: 300px;
    }
`
const DotWrap = styled.div`
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    backface-visibility: hidden;
    perspective: 1000;
    span {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #eee;
        margin-right: 10px;
    }
    span.active {
        background: #000;
    }
    @media screen and (max-width:768px) { 
        display: none;
    }
`
const ScenarioWrapStyle = styled.section`
    width: 100%;
    height: 100vh;
    position: relative;
    margin-top: 20px;
    .arrow-wrap {
        position: absolute;
        z-index: 333;
        width: 100%;
        height: 100%;
        button {
            width: 64px;
            height: 64px;
            display: block;
            position: absolute;
            top: 50%;
            margin-top: -32px;
        }
    }
    .arrow-wrap button.arrow-left {
        left: 20px;
        background: url(${envAddr}images/common/left_normal.png) no-repeat;
        background-size: cover;
    }
    .arrow-wrap button.arrow-right {
        right: 20px;
        background: url(${envAddr}images/common/right_normal.png) no-repeat;
        background-size: cover;
    }
    @media screen and (max-width:768px) {
        height: auto;
        margin-bottom: 40px;
        .arrow-wrap{
            display: none;
        } 
    }
`