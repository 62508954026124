import React, {useEffect, useState} from "react";
import styled from "styled-components";
import envAddr from "../api/env";
import { getFooterList } from "../api/http";
import { Link } from "react-router-dom";

const Footer = (props:any)=> {
    const [list, setList] = useState([]);
    const [curIndex, setCurIndex ] = useState(null);
    const lang = window.sessionStorage.getItem('lang') || window.navigator.language.toLowerCase() || 'en';
    //const history = useHistory();
    
    useEffect(() => {
        async function getInfo() {
            const navlist = await getFooterList()
			setList(navlist['data'])
        }
		getInfo()
	}, [])
    return (
        <FooterWrap>
            <nav className="footer-nav">
                <div className="links">
                    <a href="/about/index" target="_self">{lang === "zh-cn"?"关于我们":"About Us"}</a>
                    <a href={lang === "zh-cn"?"https://weibo.com/u/7829678418":"https://weibo.com/u/7829678418"} target="_blank" rel="noreferrer">{lang === "zh-cn"?"新闻":"News"}</a>
                    <a href="/home-solutions/index" target="_self">{lang === "zh-cn"?"解决方案":"Home Solution"}</a>
                    <a href="/all-products/index" target="_self">{lang === "zh-cn"?"产品库":"Product Library"}</a>
                    <a href="/jobs/jobs" target="_self">{lang === "zh-cn"?"招贤纳士":"Careers"}</a>
                    <a href="/franchise-cooperation/index" target="_self">{lang === "zh-cn"?"商务合作":"Business"}</a>
                </div>
                <div className="directory-column-section">
                {
                    list && list.length > 0 && list.map((elem, index) => (
                        <div className = {`footer-directory-column ${curIndex === index ? "active": ""}`} key={index}>
                            <h3 className="directory-column-title" onClick = {e => {index === curIndex ? setCurIndex(null) : setCurIndex(index)}}>{elem.title}</h3>
                            <ul>
                                {
                                    elem.sub_list.map((child:any, childIndex:number) => {
                                        return<li key={childIndex}><Link to={child.link}> {child.title} </Link></li>
                                    })
                                }
                            </ul>
                        </div>
                    ))
                }
                </div>
            </nav>
            <section className="footer-legal-wrap">
                <div className="info-wrap">
                    {
                        lang==="en"?
                        <p className="icon-links-wrap">
                            <a href="https://www.facebook.com/KNOW-Smart-Home-107880874909051" target="_blank" rel="noreferrer" className="icon-fb"> </a>
                            <a href="https://youtube.com/channel/UC1Gv5dertBGyEM281rOQGXA" target="_blank" rel="noreferrer" className="icon-youtube"> </a>
                            <a href="https://www.linkedin.com/company/know-smart-home/" target="_blank" rel="noreferrer" className="icon-linkedin"> </a>
                            <a href="https://twitter.com/RoomInSmartHome" target="_blank" rel="noreferrer" className="icon-tw"> </a>
                        </p>
                    :""
                }
                </div>
                <p className="link-legal-wrap">
                    <a href="https://map.baidu.com/search/%E5%8C%97%E4%BA%AC%E5%B8%82%E6%9C%9D%E9%98%B3%E5%8C%BA%E5%8C%97%E8%8B%91%E8%B7%AF%E4%B9%99108%E5%8F%B75%E5%B9%A21%E5%B1%827%E5%8F%B7/@12961619.155,4839827.69,19z?querytype=s&amp;da_src=shareurl&amp;wd=%E5%8C%97%E4%BA%AC%E5%B8%82%E6%9C%9D%E9%98%B3%E5%8C%BA%E5%8C%97%E8%8B%91%E8%B7%AF%E4%B9%99108%E5%8F%B75%E5%B9%A21%E5%B1%827%E5%8F%B7&amp;c=257&amp;src=0&amp;pn=0&amp;sug=0&amp;l=13&amp;b=(12591407,2618425;12647343,2649465)&amp;from=webmap&amp;biz_forward=%7B%22scaler%22:2,%22styles%22:%22pl%22%7D&amp;device_ratio=2" target="_blank" rel="noreferrer">{lang==="zh-cn"?"© inSpace 2024北京影智科技有限公司":"inSpace 2024 Beijing Know Smart Technology Co., Ltd"}</a>
                    <a href="http://beian.miit.gov.cn/" target="_blank" rel="noreferrer">{lang === "zh-cn"?"京ICP备20007882-1号":"Jing ICP Bei No. 20007882-1"}</a>
                    <span>{lang === "zh-cn"?"服务热线：400 - 0708 - 666":"overseas@knowin.com"}  {lang === "zh-cn"?"周一至周日 9:00 - 21:00":""} </span>
                </p>
            </section>
        </FooterWrap>
    )
}

const FooterWrap = styled.footer`
    width       : 100%;
    position    : relative;
    background  : #000;
    padding     : 66px 7.8125% 40px 7.8125%;
    box-sizing : border-box;

    .directory-column-title {
        color: #fff;
    }
    .footer-nav {
        display: flex;
        align-items: center;
    }
    .links {
        margin-right: 160px;
        a {
            color: #fff;
            display: block;
            font-size: 60px;
            line-height: 90px;
            font-weight: bold;
        }
    }
    .directory-column-section {
        display: flex;
        h3 {
            font-size: 18px;
        }

        a {
            color: #fff;
            font-size: 16px;
        }

        ul li {
            margin-top: 20px;
        }
        >div {
            margin-right: 100px;
        }
    }

    .footer-legal-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top : 80px;
    }

    .icon-fb, .icon-youtube, .icon-linkedin, .icon-tw {
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 35px;
        height: 35px;
    }
    .icon-fb {
        background-image: url(${envAddr}images/common/logo-fonts/facebook.png);
    }
    .icon-youtube {
        background-image: url(${envAddr}images/common/logo-fonts/youtube.png);
    }
    .icon-linkedin {
        background-image: url(${envAddr}images/common/logo-fonts/linkedin.png);
    }
    .icon-tw {
        background-image: url(${envAddr}images/common/logo-fonts/twitter.png);
    }
    .link-legal-wrap {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        color: #fff;
        font-size: 12px;
        a {
            font-size:12px;
            margin-right: 40px;
            color: #fff;
        }
    }
    .info-wrap {
        display: flex;
        align-items: center;
    }
    .icon-links-wrap {
        margin-left: 60px;
        a {
            margin-right: 20px;
        }
    }

    @media screen and (max-width:768px) {
        padding: 80px 20px 40px 20px;
        .footer-nav, .link-legal-wrap, .footer-legal-wrap {
            display: block;
            width: 100%;
        }
        .link-legal-wrap {
            a, span {
                display: block;
                width: 100%;
                line-height: 22px;
            }
        }
        .links {
            a {
                font-size: 30px;
                line-height: 52px;
            }
        }     

        .footer-directory-column {
            padding      : 10px 0;

            ul {
                height    : 0;
                overflow  : hidden;
                transition: all .5s;
                backface-visibility: hidden;
                perspective: 1000;
            }

            h3.directory-column-title {
                position: relative;

                &:after {
                    position : absolute;
                    content  : "+";
                    color    : #fff;
                    right    : 0px;
                    display  : inline-block;
                    font-size: 16px;
                }
            }
        }

        .footer-directory-column.active {
            h3.directory-column-title {
                &:after {
                    content: "-";
                }
            }

            ul {
                height  : auto;
                overflow: hidden;
            }
        }

        .footer-legal-wrap {
            border-top: 1px solid #fff;
            padding-top: 20px;
            margin-top: 40px !important;
        }

        .directory-column-section ul li:not(:first-child) {
            margin-top: 10px;
        }

        .directory-column-section ul li:last-child {
            margin-bottom: 10px;
        }
        .directory-column-section {
            display: block;
            margin: 80px 0 80px 0;
            >div {
                padding-top:6px;
            }
        }
        .info-wrap {
            display: block;
        }
        .icon-links-wrap {
            margin-left: 0;
            margin-top: 20px;
        }
    }
`;

export default Footer;