import React, { useState, useEffect } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { Spec } from "../../components/spec";
import { getSpotlightSpec } from "../../api/http";
const SpotlightSpec = () => {
    const [list, setList] = useState([]);
    const [order, setOrder] = useState(Number);
    const [title, setTitle] = useState('');
    const [subNav, setSubNav] = useState([]);
    useEffect(() => {
        async function getInfo() {
            const dataInfo = await getSpotlightSpec();
            const {data, order, title, sub_nav} = dataInfo;
            setList(data);
            setOrder(order);
            setTitle(title);
            setSubNav(sub_nav)
        }
        getInfo();
    }, []);
    return (
        <React.Fragment>
            <Header curDataIndex={order} title="" subnav={subNav}/>
            <div className="main-wrap" id="mainIndex">
                <main className="main-content" role="main">
                    <Spec title={title} list={list}/>
                </main>
                <Footer/>
            </div>
        </React.Fragment>
    )
}

export default SpotlightSpec;