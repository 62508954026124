import styled from "styled-components";
import envAddr from "../../api/env";
import { envAddrInSpace, inSpaceImg } from "../../api/env";
const Figure = styled.figure<{bgImg:string, bgFormat:string, isFull:boolean | false,custom_style:Record<string,string>}>`
    width: ${(props) => props.isFull ? "100%" : "calc(100vw * .53333)"};
    height: ${(props) => props.isFull ? "100%" : "calc(100vw * .53333 * .5625)"};
    position: ${(props) => props.isFull ? "absolute" : "relative"};
    ${(props) => !props.isFull?"margin:0 auto":""};
    top: 0;
    left: 0;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image:url(${(props) => props.bgImg ? inSpaceImg.includes(props.bgImg) ? envAddrInSpace + props.bgImg + props.bgFormat : envAddr + props.bgImg + props.bgFormat:""});
    margin-top: ${(props) => props.isFull ? "0" : "5.21vw"};
    @media screen and (max-width: 768px) {
        width: 100%;
        height: ${(props) => props.isFull ? "100%" : props.custom_style?.height ? props.custom_style.height:"calc(100vw * .496)"}; 
        margin-top: ${(props) => props.isFull ? "0" : "10.667vw"};
    }
    ${props=>({...props.custom_style})}
`
export default Figure;