import Index from "../pages/index";
import Insight from "../pages/insight/index";
import InsightScenario from "../pages/insight/scenario";
import InsightSpec from "../pages/insight/spec";
import InsightPro from "../pages/insight-pro/index";
import InsightProScenario from "../pages/insight-pro/scenario";
import InsightProSpec from "../pages/insight-pro/spec";
import AioBox from "../pages/aiobox/index";
import AioBoxSpec from "../pages/aiobox/spec";
import Panel from "../pages/panel/index";
import PanelScenario from "../pages/panel/scenario";
import PanelSpec from "../pages/panel/spec";
import SmartDrive from "../pages/smart-drive/index";
import SmartDriveSpec from "../pages/smart-drive/spec";
import SmartCurtain from "../pages/smart-curtain/index";
import SmartCurtainSpec from "../pages/smart-curtain/spec";
import SmartIncare from "../pages/smart-incare/index";
import SmartDoor from "../pages/smart-door/index";
import SmartDoorSpec from "../pages/smart-door/spec";
import Store from "../pages/store/store";
import Coffeerobot from "../pages/coffeerobot/index";
import CoffeerobotScenario from "../pages/coffeerobot/scenario";
import CoffeerobotSpec from "../pages/coffeerobot/spec";
import CoffeerobotT from "../pages/coffeerobot-t/index";
import CoffeerobotTSpec from "../pages/coffeerobot-t/spec";
import KitchenRobot from "../pages/kitchen-robot/index";
import About from "../pages/about/index";
import Jobs from "../pages/jobs/jobs";
import AllProductsList from "../pages/all-products/index";
import SceneSwitch from "../pages/scene-switch/index";
import SceneSwitchSpec from "../pages/scene-switch/spec";
import SceneSwitchScenario from "../pages/scene-switch/scenario";
import HomeSolutions from "../pages/home-solutions/index";
import Spotlight from "../pages/spotlight/index";
import SpotlightSpec from "../pages/spotlight/spec";
import Downlight from "../pages/downlight/index";
import DownlightSpec from "../pages/downlight/spec";
// import Intr from "../pages/about/intr";
import Bicycle from "../pages/bicycle/index";
import Insight10 from "../pages/insight10/index";
import Insight10Spec from "../pages/insight10/spec";
import InsightOS from "../pages/insightos/index";
import InLife from "../pages/inlife/index";
import FranchiseCooperation from "../pages/franchise-cooperation/index";
import LightBelt from "../pages/light-belt/index";
import GasAlarm from "../pages/gas-alarm/index";
import WindowSensor from "../pages/window-sensor/index";
import SmokeAlarm from "../pages/smoke-alarm/index";
import WaterSensor from "../pages/water-sensor/index";
import BodySensor from "../pages/body-sensor/index";
import Inlight from "../pages/inlight/index";
import InsightAir from "../pages/insight-air/index";
import InsightAirSpec from "../pages/insight-air/spec";
import CooperationCaseHongshuwan from "../pages/cooperation-case/hongshuwan";
import CooperationCaseHuanyutianxia from "../pages/cooperation-case/huanyutianxia";
import CooperationCaseYuanyang from "../pages/cooperation-case/yuanyang";
import CooperationCaseJinghuijituan from "../pages/cooperation-case/jinghuijituan";
import DoorPhone from "../pages/door-phone/index";
import DoorPhoneSpec from "../pages/door-phone/spec";

const RouteConfig = [
	{
		path: '/',
		label: '首页',
		exact: true,
		component: Index
	},
	{
		path: '/index',
		label: '首页',
		exact: true,
		component: Index
	},
	{
		path: '/insight/index',
		label: 'insight',
		exact: true,
		component: Insight
	},
	{
		path: '/insight/scenario',
		label: 'scenario',
		exact: true,
		component: InsightScenario
	},
	{
		path: '/insight/spec',
		label: 'spec',
		exact: true,
		component: InsightSpec
	},
	{
		path: '/insight-pro/index',
		label: 'insight-pro',
		exact: true,
		component: InsightPro
	},
	{
		path: '/insight-pro/scenario',
		label: 'scenario',
		exact: true,
		component: InsightProScenario
	},
	{
		path: '/insight-pro/spec',
		label: 'spec',
		exact: true,
		component: InsightProSpec
	},
	{
		path: '/aiobox/index',
		label: 'aiobox',
		exact: true,
		component: AioBox
	},
	{
		path: '/aiobox/spec',
		label: 'spec',
		exact: true,
		component: AioBoxSpec
	},
	{
		path: '/panel/index',
		label: '智能开关',
		exact: true,
		component: Panel
	},
	{
		path: '/panel/scenario',
		label: 'scenario',
		exact: true,
		component: PanelScenario
	},
	{
		path: '/panel/spec',
		label: 'spec',
		exact: true,
		component: PanelSpec
	},
	{
		path: '/smart-drive/index',
		label: '智能驱动',
		exact: true,
		component: SmartDrive
	},
	{
		path: '/smart-drive/spec',
		label: '智能驱动spec',
		exact: true,
		component: SmartDriveSpec
	},
	{
		path: '/smart-curtain/index',
		label: '窗帘电机',
		exact: true,
		component: SmartCurtain
	},
	{
		path: '/smart-curtain/spec',
		label: 'spec',
		exact: true,
		component: SmartCurtainSpec
	},
	{
		path: '/smart-incare/index',
		label: '智能安环',
		exact: true,
		component: SmartIncare
	},
	{
		path: '/smart-door/index',
		label: '智能门',
		exact: true,
		component: SmartDoor
	},
	{
		path: '/smart-door/spec',
		label: 'spec',
		exact: true,
		component: SmartDoorSpec
	},
	{
		path: '/coffeerobot/index',
		label: '咖啡机器人P系列',
		exact: true,
		component: Coffeerobot
	},
	{
		path: '/coffeerobot/scenario',
		label: '咖啡机器人P系列场景',
		exact: true,
		component: CoffeerobotScenario
	},
	{
		path: '/coffeerobot/spec',
		label: 'spec',
		exact: true,
		component: CoffeerobotSpec
	},
	{
		path: '/coffeerobot-t/index',
		label: '咖啡机器人T系列',
		exact: true,
		component: CoffeerobotT
	},
	{
		path: '/coffeerobot-t/spec',
		label: 'spec',
		exact: true,
		component: CoffeerobotTSpec
	},
	{
		path: '/kitchen-robot/index',
		label: '厨房机器人',
		exact: true,
		component: KitchenRobot
	},
	{
		path: '/about/index',
		label: '关于我们',
		exact: true,
		component: About
	},
	{
		path: '/jobs/jobs',
		label: '加入我们',
		exact: true,
		component: Jobs
    },
    {
		path: '/store/store',
		label: '门店',
		exact: true,
		component: Store
	},
	{
		path: '/all-products/index',
		label: '所有产品列表',
		exact: true,
		component: AllProductsList
	},
	{
		path: '/scene-switch/index',
		label: '智能情景开关',
		exact: true,
		component: SceneSwitch
	},
	{
		path: '/scene-switch/spec',
		label: '智能情景开关参数',
		exact: true,
		component: SceneSwitchSpec
	},
	{
		path: '/scene-switch/scenario',
		label: '智能情景开关场景',
		exact: true,
		component: SceneSwitchScenario
	},
	{
		path: '/home-solutions/index',
		label: '智能家居解决方案',
		exact: true,
		component: HomeSolutions
	},
	{
		path: '/spotlight/index',
		label: '影智高显射灯',
		exact: true,
		component: Spotlight
	},
	{
		path: '/spotlight/spec',
		label: '影智高显射灯 产品参数',
		exact: true,
		component: SpotlightSpec
	},
	{
		path: '/downlight/index',
		label: '影智高显筒灯',
		exact: true,
		component: Downlight
	},
	{
		path: '/downlight/spec',
		label: '影智高显筒灯 产品参数',
		exact: true,
		component: DownlightSpec
	}, 
	// {
	// 	path: '/about/intr',
	// 	label: '关于我们',
	// 	exact: true,
	// 	component: Intr
	// },
	{
		path: '/bicycle/index',
		label: '单车',
		exact: true,
		component: Bicycle
	},
	{
		path: '/insight10/index',
		label: 'insight10',
		exact: true,
		component: Insight10
	},
	{
		path: '/insight10/spec',
		label: 'insight10spec',
		exact: true,
		component: Insight10Spec
	},
	{
		path: '/insightos/index',
		label: 'insightos',
		exact: true,
		component: InsightOS
	},
	{
		path: '/inlife/index',
		label: 'inlife',
		exact: true,
		component: InLife
	},
	{
		path: '/franchise-cooperation/index',
		label: '加盟合作',
		exact: true,
		component: FranchiseCooperation
	},
	{
		path: '/light-belt/index',
		label: '灯带',
		exact: true,
		component: LightBelt
	},
	{
		path: '/gas-alarm/index',
		label: '燃气报警器',
		exact: true,
		component: GasAlarm
	},
	{
		path: '/window-sensor/index',
		label: '门窗传感器',
		exact: true,
		component: WindowSensor
	},
	{
		path: '/smoke-alarm/index',
		label: '烟雾报警器',
		exact: true,
		component: SmokeAlarm
	},
	{
		path: '/water-sensor/index',
		label: '水浸传感器',
		exact: true,
		component: WaterSensor
	},
	{
		path: '/body-sensor/index',
		label: '人体传感器',
		exact: true,
		component: BodySensor
	},
	{
		path: '/inlight/index',
		label: 'inlight',
		exact: true,
		component: Inlight
	},
	{
		path: '/insight-air/index',
		label: 'insight-air',
		exact: true,
		component: InsightAir
	},
	{
		path: '/insight-air/spec',
		label: 'spec',
		exact: true,
		component: InsightAirSpec
	},
	{
		path: '/cooperation-case/yuanyang',
		label: '远洋',
		exact: true,
		component: CooperationCaseYuanyang
	},
	{
		path: '/cooperation-case/huanyutianxia',
		label: '寰宇天下',
		exact: true,
		component: CooperationCaseHuanyutianxia
	},
	{
		path: '/cooperation-case/hongshuwan',
		label: '红树湾',
		exact: true,
		component: CooperationCaseHongshuwan
	},
	{
		path: '/cooperation-case/jinghuijituan',
		label: '无锡金辉 · 天奕铭著',
		exact: true,
		component: CooperationCaseJinghuijituan
	},
	{
		path: '/door-phone/index',
		label: '影智楼宇门口机',
		exact: true,
		component: DoorPhone
	},
	{
		path: '/door-phone/spec',
		label: '影智楼宇门口机参数',
		exact: true,
		component: DoorPhoneSpec
	}
]

// export default routeConfig);
export { RouteConfig }