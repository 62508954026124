import React,{useState, useEffect, createRef, useRef}  from "react";
import styled from "styled-components";
import envAddr from "../api/env";
import { envAddrInSpace, inSpaceImg } from "../api/env";
import { variablesStyle } from "../style/variablesStyle";
import {vh} from "../style/mixin";
import { Bouns, ScaleSmall} from "../style/animation";
import { Link } from "react-router-dom";
import { getNavList} from "../api/http";
import { isDevices } from "../js/device"
import { getLang } from "../js/isLang"

const InnerBox = (props:any) => {
    let ua = window.navigator.userAgent,
        os = isDevices(ua);
        
    const lang = getLang();
    // 初始数据
    const [list, setList] = useState([])
    
    // 初始导航索引
    const dataIndex = useRef(props.dataIndex);

    // 当前导航索引
    const [curIndex, setCurIndex] = useState(props.dataIndex)

    // style
    const [divStyle, setDivStyle] = useState({left:"0", transition: "none"})
    
    // 当前导航容器 
    const navListRef = createRef<HTMLUListElement>()

    /*
        @changeLine : Function // 线条跟随效果
        @initWidth : number // 线条宽度 pc || ipad横屏时使用(1024)
        @distanceTop :  number // 线条距顶部距离 mobile || ipad竖屏时使用(768)
        @distance : number // 线条滑动的间距
        @cur : number // 当前索引
        @lineWrap : htmlElement // 线条div容器
        @liList : htmlLiElement // 线条跟随的对像 (li列表)
    */
    let changeLine = (index:number) => {
        let initWidth = 0,
            initLeft = 0,
            distanceTop = 0,
            distance = os.isPc ? 26 : 30,
            initLeftDistance = 0,
            lineWrap = document.getElementsByClassName('inner-links-line')[0],
            cur = index;
        
        const liList = navListRef.current.querySelectorAll('li');
        
        let deviceChange = (device = true) => {
            if (device && liList) {
                initWidth = liList && liList[cur] ? liList[cur].offsetWidth : 0;
                
                let k = cur;
                
                while (k > 0) {
                    k--;
                    initLeftDistance += liList[k].offsetWidth;
                }

                initLeft = cur * distance * 2 + initLeftDistance + distance;
    
                lineWrap['style']['width'] = initWidth + 'px';
                lineWrap['style']['left'] = initLeft + 'px';
                lineWrap['style']['top'] = 'auto';
            } else {

                lineWrap['style']['left'] = 0;
                lineWrap['style']['width'] = '3px';

                if (cur > 0) {
                    distanceTop = liList[0].offsetHeight * cur  + (cur + 1) * distance;
                    lineWrap['style']['top'] = distanceTop + 'px'
                } else {
                    lineWrap['style']['top'] = distance + 'px';
                }
            }
        }

        if (navListRef && navListRef.current && liList) {
            if (os.isPc) {
                deviceChange(true);
            } else {
                const clientWidth = document.documentElement.clientWidth;
                if (clientWidth > 768) {
                    // ipad 横屏
                    distance = 26;
                    deviceChange(true);
                } else {
                    deviceChange(false);
                }
            }
        }
    }
    // 产品导航显示后是否允许滚动
    let isCanScroll = (isScroll = true) => {
        let h = document.getElementById('innerProducts').offsetHeight - 60,
            body = document.getElementsByTagName('body')[0],
            clientHeight = document.body.clientHeight;
        if (isScroll) {
            h < clientHeight ? body.classList.add('noscroll') : body.classList.remove('noscroll');
        } else {
            body.classList.remove('noscroll');
        }
    }
    useEffect(() => {

        const resizeChange = ()=> {
            if (!props.isShow) {
                changeLine(curIndex);
            }
        }

        window.addEventListener('resize', resizeChange, false);

        return ()=> {
            window.removeEventListener('resize', resizeChange, false);
        }
    })
	useEffect(() => {
        getNavList().then(res => {
            setList(res['data']);
        })
        if (!props.isShow) {
            if (typeof(props.dataIndex) === 'number') {
                changeLine(props.dataIndex)
            }
            setDivStyle({left:`calc(-100vw * ${props.dataIndex}`, transition: "none"});
            isCanScroll(true)
        } else {
            isCanScroll(false)
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isShow])

    let changeNav = (index: number) => {
        setCurIndex(index)
        changeLine(index)
        setDivStyle({left: `calc(-100vw * ${index})`, transition: "all .4s linear"})
    }
    
    return (
        <InnerBoxWrap id="productDetailWrap" {...props} length={list.length}>
        {    
            <div id="innerWrap" className="inner-wrap">
                <div className="inner-header">
                    <div className="inner-nav-list-wrap">
                        <div className={ `${lang !== "en"  ? "inner-logo" : "en-inner-logo"}`}>
                            <Link to="/index"></Link>
                        </div>
                        <div className="inner-links-wrap">
                            <ul className="inner-links" id="innerNav" ref={navListRef}>
                                {
                                    list.map((elem, index) => {
                                        return<li data-index={ elem['order']} className={curIndex === elem['order'] ? 'active': ""} key={index}>
                                            {
                                                !!elem['isLink'] ? <a href="/coffeerobot-t/index"> {elem['title']} </a> : <Link to="/" onClick={(e)=>{ e.preventDefault(); changeNav(elem['order'])}}> {elem['title']} </Link>
                                            }
                                            
                                        </li>
                                    })
                                }
                            </ul>
                            <div className="inner-links-line"></div>
                        </div>
                        <Link className="inner-close" to="/" onClick={(e) => {e.preventDefault(); props.changeShow(false); setCurIndex(dataIndex); props.changeIndex(dataIndex)}}></Link>
                    </div>
                </div>
                <div className="inner-content" onClick={(e) => {e.preventDefault(); props.changeShow(false); setCurIndex(dataIndex); props.changeIndex(dataIndex)}}>
                    <div id="innerProducts" style={divStyle}>
                        {
                            list.map((elem, index) => {
                                let subList:[] = elem['sub_list'];
                                return<div className={curIndex === elem['order'] ? "inner-products-horizontal active": "inner-products-horizontal"} data-index={elem['order']} key={index}>
                                        <ul> 
                                            {
                                                subList.map((subElem, index) => {
                                                    return<li key={index}>
                                                        <LinkWrap to={subElem['link']} className = {`list-link-wrap ${subElem['classname'][0]}`} background-image={subElem['poster_img']}>
                                                            <div>
                                                                <h3 dangerouslySetInnerHTML={{__html: subElem['title']}}></h3>
                                                                <p dangerouslySetInnerHTML={{__html: subElem['subtitle']}}></p>
                                                            </div>
                                                            {subElem['new_mark'] ? <em className="new_mark">{lang === "zh-cn"?"新品":"New"}</em> : ""}
                                                        </LinkWrap>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                            })
                        }
                    </div>
                </div>
            </div>
        }
        </InnerBoxWrap>
    )
}

export default InnerBox;

const LinkWrap = styled(Link)`
    background-image:url(${(props:any) => inSpaceImg.includes(props['background-image']) ? envAddrInSpace + props['background-image'] :  envAddr + props['background-image']});
`
export const InnerBoxWrap = styled.div`
    backface-visibility: hidden;
    animation: ${(props:any) => props.isShow ? ScaleSmall : Bouns} .4s linear forwards;
    visibility: ${(props:any) => props.isShow ? 'hidden': 'visible'};
    position  : fixed;
    width     : 100%;
    height    : 100%;
    overflow-y: scroll;
    top       : 0;
    left      : 0;
    z-index   : 999999;
    background: rgba(0, 0, 0, .2);
    backdrop-filter: saturate(180%) blur(20px);
    box-sizing: border-box;

    
    .inner-wrap {
        min-width : 320px;
        width     : 100%;
        height: 100%;
        overflow-y: scroll;
        margin    : 0 auto;
        position  : relative;
        transition: all linear .3s;
        backface-visibility: hidden;
    }
    .inner-header {
        background : #000;
        ${vh(variablesStyle.headerHeight)}
        box-sizing: border-box;
        position   : fixed;
        top        : 0;
        left       : 0;
        padding    : 0 30px;
        z-index    : 44;
        width      : 100%;
    }

    .inner-nav-list-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        
        .inner-logo a, .en-inner-logo a{
            position: relative;
            display: inline-block;
            width: ${variablesStyle.pcLogoWidth};
            height: ${variablesStyle.pcLogoHeight};
            background-repeat: no-repeat;
            background-size: 100%;
            vertical-align: middle;
            margin-top: -8px;
        }
        .inner-logo a{
            background-image: url(${envAddrInSpace}images/common/web/logo.png);
        }
        .en-inner-logo a {
            width: 62px;
            height: 20px;
            background-image: url(${envAddrInSpace}images/common/web/logo.png);
        }
    }
    .inner-links-wrap {
        position: relative;
        overflow-x: hidden;
    
        ul {
            backface-visibility: hidden;
            perspective: 1000;
            position: relative;
            transition : all ease .5s;
        }
    
        li {
            float   : left;
            margin : 0 26px; 
            position: relative;
        }
    
        a {
            color      : rgba(255, 255, 255, .8);
            font-size  : 15px;
            font-weight: 400;
            display: inline-block;
        }
    }

    .m-inner-links {
        li.active {
            &:after {
                content   : "";
                display   : inline-block;
                width     : calc(100% - 20px);
                left      : 10px;
                bottom    : 2px;
                position  : absolute;
                height    : 3px;
                background: #00a4fe;
            }
        }
    }

    .inner-links-line {
        position  : absolute;
        left      : 0;
        bottom    : 0;
        height    : 3px;
        background: linear-gradient(to right,#4adad1,#4eadde);
        color: transparent;
        transition: all ease .5s;
        backface-visibility: hidden;
        perspective: 1000;
    }

    .inner-close {
        width              : 22px;
        height             : 22px;
        background-repeat  : no-repeat;
        background-size    : cover;
        background-position: center;
        background-image: url(${envAddr}images/common/icon_close_normal.png);
    }

    .inner-content {
        padding-top: ${variablesStyle.headerHeight};
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        overflow-y: scroll;
    }

    /* 不显示滚动条 */
    #innerProducts ::-webkit-scrollbar,
    #innerProducts>div ::-webkit-scrollbar {
        width: 0 !important
    }

    #innerProducts,
    #innerProducts>div {
        -ms-overflow-style: none;
    }

    #innerProducts,
    #innerProducts>div {
        overflow: -moz-scrollbars-none;
    }



    #innerProducts {
        width                     : 100%;
        position                  : relative;
        scrollbar-width           : none;
        /* firefox */
        -ms-overflow-style        : none;
        /* IE 10+ */
        -webkit-overflow-scrolling: touch;
        backface-visibility: hidden;
        perspective: 1000;
        transition: ${(props:any) => props.isShow ? "none": "all linear .4s"}; 
        display: flex;
        justify-content : start;
        a {
            color  : #fff;
            display: block;
        }
        
        >div {
            width     : 100%;
            flex-shrink: 0;
            ul {
                width   : 100%;
                background : #000;

                li {
                    float     : left;
                    width     : 25%
                    margin    : 0;
                    padding   : 0;
                    text-align: center;
                    position  : relative;

                    &:hover {
                        background: #171717;

                        a.list-link-wrap {
                            &:after {
                                background: transparent;
                            }
                            background-size    : 106% 106%;
                            transition: all 1s ease-out;
                            backface-visibility: hidden;
                            perspective: 1000;
                        }
                    }


                    a.center-center {
                        justify-content: center;
                        align-items: center;
                    }

                    a.bottom-center {
                        justify-content: center;
                        align-items: bottom;
                    }
                    
                    
                }

            }
        }

        .new_mark {
            font-size: 16px;
            position: absolute;
            right: 20px;
            top: 20px;
            font-style: normal;
            background: linear-gradient(to right,#4adad1,#4eadde);
            -webkit-background-clip: text;
            color: transparent;
            z-index: 2;
        }

        .list-link-wrap {
            width: 100%;
            height: 340px;
            height: calc(100vw * 0.3333 * 0.53125);;
            display            : block;
            background-repeat  : no-repeat;
            background-position: center center;
            background-size    : 100% 100%;
            display: flex;
            position: relative;
            &:after {
                content   : "";
                display   : block;
                position  : absolute;
                width     : 100%;
                height    : 100%;
                background: rgba(0, 0, 0, .3);
                left      : 0;
                top       : 0;
            }
            
            h3 {
                font-size: 24px;
                i.know-fonts {
                    font-size: calc(18 * 2.3px);
                }
            }

            p {
                font-size: 18px;
                i.know-fonts {
                    font-size: 1.1em;
                }
            }
        }

        li {
            overflow: hidden;
        }

        .inner-products-horizontal {
            ul {
                li {
                    width: 33.33% !important;
                }

            }
        }
    }

    @media screen and (max-width:1025px) {
        .inner-links-wrap {
            margin-left: 50px;
        }

        #innerProducts {
            span {
                font-size: 14px;
                bottom   : 16px !important;
            }
        }
    }

    @media screen and (max-width:800px) {
        #innerProducts {
            >div {
                ul {
                    li {
                        width: 33.33%;
                    }

                }
            }

            >div.inner-products-horizontal {
                ul {
                    li {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    @media screen and (max-width:768px) {
        
        .new-mark {
            font-size: 12px;
        }
        .inner-wrap {
            height: 100%;
        }
        .inner-content {
            height: 100%;
            padding-top: 52px;
        }
        
        #innerProducts {
            .list-link-wrap:after {
                background:rgba(255, 255, 255, 0);
            }
            height: 100%;
            // overflow-y: scroll;
            >div.inner-products-horizontal ul li {
                width: 65% !important;
                margin-left: 35%;
            }
            > div {
                height: 100%;
                overflow-y: scroll;
            }

            .inner-products-horizontal {
                a.list-link-wrap {
                    height: calc(100vw * 0.65 * 0.54);
                }
            }
            
            .list-link-wrap {
                h3 {
                    font-size: 20px;
                    i.know-fonts {
                        font-size: calc(12 * 2.3px);
                    }
                }
    
                p {
                    font-size: 12px;
                    i.know-fonts {
                        font-size: 1.1em;
                    }
                }
            }
        }
        .know-fonts {
            font-size: 1em;
        }

        .inner-links-wrap {
            position     : fixed;
            left : 0;
            top: 52px;
            width: 39%;
            height: calc(100% - 52px);
            background: #000;
            margin: 0;
            overflow: hidden;
            
            box-sizing: border-box;

           a {
                font-size: 16px;
                padding-left: 16px;
            }
    
            li {
                padding: 0;
                margin     : 30px 0 0 0;
                display: block;
                float: none;
                line-height: 32px;
            }
    
            li:first-child {
                padding-left: 0;
            }
        }

        .inner-links-line {
            bottom: auto;
            height: 32px;
            width: 3px;
         }
         .inner-header {
             height     : 52px;
             line-height: 52px;
             padding    : 0 24px;
         }
     
        .inner-nav-list-wrap {
            .inner-logo{
                line-height: 52px;
                margin-top : 0;
                left       : 24px;
            }
        }
    }
`