import React, {
  forwardRef,
  useImperativeHandle,
  ReactNode,
  useState,
  useEffect,
  useRef
} from 'react';
import styled, { keyframes } from 'styled-components';

interface Prop {
  children: ReactNode;
  width?: number;
  height?: number;
  bg?: string;
}

// 暴露的方法
export interface PopoverExpose {
  toggle: (e: Event) => void;
  show: (e: Event) => void;
  hide: (e: Event) => void;
}

const Popover = forwardRef<PopoverExpose, Prop>(
  ({ children, width = 200, height = 500, bg = '#fff' }, ref) => {
    const [visible, setVisible] = useState(false);
    const [posi, setPosi] = useState({ left: 0, top: 0 });
    const bindEle = useRef<HTMLElement | null>(null); // 绑定的dom元素
    const popoverWrap = useRef<HTMLElement | null>(null);

    const toggle = (e: Event) => {
      changePopoverPosition(e);
      setVisible(!visible);
      bindEle.current = e.target as HTMLElement;
    };
    const show = (e: Event) => {
      if (!visible) {
        setVisible(true);
        changePopoverPosition(e);
        bindEle.current = e.target as HTMLElement;
      }
    };
    const hide = (e: Event) => {
      if (visible) {
        setVisible(false);
        changePopoverPosition(e);
        bindEle.current = e.target as HTMLElement;
      }
    };
    function changePopoverPosition(e) {
      let left = 0,
        top = 0;
      const separate = 10;
      const currentTarget = e.currentTarget as HTMLElement;
      const { offsetTop, offsetLeft } = currentTarget.parentNode as HTMLElement;
      left = offsetLeft - width - separate;
      top =
        offsetTop +
        Math.round(currentTarget.offsetHeight / 2) -
        Math.round(height / 2) -
        separate;
      setPosi({ left, top });
    }

    useEffect(() => {
      const clickOutSideHandle = (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        if (
          popoverWrap.current.contains(target) ||
          (bindEle.current && bindEle.current.contains(target))
        )
          return false;
        setVisible(false);
      };
      document.addEventListener('click', clickOutSideHandle);
      return () => {
        document.removeEventListener('click', clickOutSideHandle);
      };
    }, []);
    useImperativeHandle(ref, () => ({
      toggle,
      show,
      hide
    }));
    return (
      <PopoverWrap
        visible={visible}
        width={width}
        height={height}
        bg={bg}
        posi={posi}
        ref={popoverWrap}>
        {children}
      </PopoverWrap>
    );
  }
);

const ZoomIn = keyframes` 
0%{
  transform:scale(0);
  opacity: 0;
}
100%{
  transform:scale(1);
  opacity: 1;
}
`;
const ZoomOut = keyframes` 
0%{
  transform:scale(1);
  opacity: 1;
  display: block;
}
100%{
  transform:scale(0);
  opacity: 0;
  display: block;
}
`;

const PopoverWrap = styled.div<{
  visible: boolean;
  width?: number;
  height?: number;
  bg?: string;
  posi: { left: number; top: number };
  ref: any;
}>`
  position: fixed;
  left: ${prop => prop.posi.left}px;
  top: ${prop => prop.posi.top}px;
  display: ${prop => (prop.visible ? 'block' : 'none')};
  width: ${prop => prop.width}px;
  height: ${prop => prop.height}px;
  background-color: ${prop => prop.bg};
  border-radius: 8px;
  box-shadow: 0 0 8px 1px rgba(255, 255, 255, 0.5);
  z-index: 99999;
  animation: ${prop => (prop.visible ? ZoomIn : ZoomOut)} 0.2s linear;
  transform-origin:right center;
`;

export default Popover;
