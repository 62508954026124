const variablesStyle = {
    fontFamilay :'Helvetica,Microsoft YaHei,Arial,sans-serif',
    headerGlobalNavHeight :'60px',
    headerNavSummaryHeight :'162px',
    headerNavListHeight :'52px',
    headerHeight :'60px',
    footerHeight :'58px',
    headerSideMenuBg :'#1A1A1A',
    headerSideWidth :'310px',
    headerBgColor :'rgba(19,19,24,1)',
    middleFontSize :'32px',
    contentWidth :'980px',
    headerFontSize :'80px',
    sectionTitleSize :'40px',
    sectionIntroSize :'18px',
    linkSize :'21px',
    txtColor :'#a1a1a6',
    linkSmallSize :'16px',
    btnBgColor :'#00a4fe',
    // pc logo width
    pcLogoWidth :'137px',
    // pc logo height
    pcLogoHeight :'20px',
    mobileLogoWidth :'60px',
    mobileLogoHeight :'20px'
}

export { variablesStyle };