import React, {useEffect, createRef, useRef} from "react";
import styled from "styled-components";
import envAddr from "../../api/env";
import { envAddrInSpace, inSpaceImg } from "../../api/env";
import Container from "../container/Container";
import Title from "../container/Title";
import SubTitle from "../container/Subtitle";
import Content from "../container/Content";
import Figure from "../container/Figure";
import Slider from "../slider/Slider";
import VideoCut from "../video/VideoCut";
import VideoFull from "../video/VideoFull";
import Icon from "../icon/Icon";
import { Award } from "../award-img/award";
import { LinkBtn } from "../btn/btn";
import { device } from "../../js/device";
const SubpageTemplate = (props:any)=> {
    const lang = window.sessionStorage.getItem("lang") ? sessionStorage.getItem("lang") : "zh";
    let wrapArr:any = [];
    for(let i = 0; i < props.list.length; i++) {
        wrapArr[i] = createRef()
    }
    // 可视区域
    let viewArea = document.body.clientHeight*2/3;

    // 当前容器
    let curIndex = useRef(0);

    const changeStyle = (elem:any, index:number)=> {
        if (elem[index].current) {
            const elemChild = elem[index].current.getElementsByClassName('anima')[0];
            elemChild.style.opacity = 1;
            elemChild.style.transform = "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)";
            elemChild.style.transition = "opacity 0.6s ease-out 0.5s, transform 0.6s ease-out 0.5s";
        }
    }

    const scroll = (event:any)=> {
        // const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false)
        // || window.pageYOffset
        // || (event.srcElement ? event.srcElement.body.scrollTop : 1);
        
        if (curIndex.current >= wrapArr.length) {
            return window.removeEventListener('scroll',scroll)
        }

        for(let i =  curIndex.current; i < wrapArr.length; i++) {
            if (wrapArr[i].current) {
                if (viewArea > (wrapArr[i].current.getBoundingClientRect().top )) {
                    changeStyle(wrapArr, i);
                    if (wrapArr[i].current.getElementsByTagName("video") && wrapArr[i].current.getElementsByTagName("video").length > 0) {
                        wrapArr[i].current.getElementsByTagName("video")[0].play();
                    }
                    curIndex.current = i;
                }
            }
        }
    }

    useEffect(()=>{
        const init = ()=> {
            for(let i = 0; i < wrapArr.length; i++) {
                if (wrapArr[i].current) {
                    if (viewArea > (wrapArr[i].current.getBoundingClientRect().top )) {
                        changeStyle(wrapArr, i);
                        if (wrapArr[i].current.getElementsByTagName("video") && wrapArr[i].current.getElementsByTagName("video").length > 0) {
                            wrapArr[i].current.getElementsByTagName("video")[0].play();
                        }
                        curIndex.current = i;
                    }
                    window.addEventListener('scroll',scroll)
                }
            }
        }
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[wrapArr])
    return (
        <React.Fragment>
            <SubpageTemplateWrap>
                {
                    (props.list || []).map((dataList, index) => (
                        <Container {...device(dataList,"block_style")} key={index} refs={wrapArr[index]}>
                            <div>
                                <div className="anima">
                                    {
                                        dataList.title.txt ||  dataList.title.img||device(dataList,'title_style','content')?.length ? <Title lang={lang} {...dataList}/> : ""
                                    }
                                    {
                                        typeof(dataList.subtitle) !== "undefined" && dataList.subtitle.length > 0 ? <SubTitle lang={lang} {...dataList}/> : ""
                                    }
                                    {
                                        (typeof(dataList.content) !== "undefined" && dataList.content.length > 0)||device(dataList,'content_style','content')?.length  ? <Content lang={lang} {...dataList}/> : ""
                                    }
                                    {
                                        dataList.is_btn ? <LinkBtn {...dataList.btn}/> : ""
                                    }
                                </div>
                                {
                                    typeof(dataList.feature_icons) !== "undefined" ? <Icon {...dataList.feature_icons}/> : ""
                                }
                                {
                                    device(dataList, "img_container") && device(dataList, "img_container", "image_url") !== ""  ? <Figure className="section-img" bgImg={device(dataList, "img_container", "image_url")} bgFormat={device(dataList, "img_container", "img_format")} isFull={device(dataList, "block_style", "is_full")} custom_style={device(dataList, "img_container",'custom_style')} />  : ""
                                }
                                {
                                    typeof(dataList.is_video) !== "undefined" && dataList.is_video === true && device(dataList, "video_container") && device(dataList, "video_container", "src") !== ""  ? <VideoFull  {...device(dataList, "video_container")} isFull={device(dataList, "block_style", "is_full")}/> : ""
                                }
                                {
                                    typeof(dataList.is_award) !== "undefined" && dataList.is_award ? <Award className="icon-award" {...device(dataList, "awards")}/> : ""
                                }
                                {
                                    typeof(dataList.feature_slider) !== "undefined" ? <Slider  lang={lang} {...dataList.feature_slider}/> : ""
                                }
                                {
                                    typeof(dataList.feature_video_clips) !== "undefined" ? <VideoCut  list={dataList.feature_video_clips.video_list} activeColor={dataList.feature_video_clips.active_color}/> : ""
                                }
                            </div>
                        </Container>
                    ))
                }
                {
                    props.extralist &&  props.extralist.length > 0 ? 
                    <InnerLayoutWrap>
                        {
                            props.extralist.map((elem:object, index:number) => {
                                return <div key={index}>
                                            <h2>{elem['title']}</h2>
                                            <LinkBtn btn_link={elem['btn_link']} btn_txt={elem['btn_txt']}/>
                                            <a href={elem['btn_link']} className="figure-wrap">
                                                <LayoutFigure background-image={elem['img_url']}/>
                                            </a>
                                        </div>
                            })
                        }
                    </InnerLayoutWrap>
                    : ""
                }
            </SubpageTemplateWrap>
        </React.Fragment>
    )
}
export default SubpageTemplate;

const SubpageTemplateWrap = styled.section`
    .anima {
        position:relative;
        z-index: 22;
        opacity: 0;
    }
`
const InnerLayoutWrap = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    background: #000;
    padding-top: 20px;
    color: #fff;
    text-align: left;
    h2 {
        z-index: 2;
        position: relative;
        text-align: left;
        z-index: 4;
        pointer-events: none;
        padding-top: 30px;
        padding-left: 50px;
        font-size: 20px;
    }
    .btn {
        z-index: 2;
        position: absolute;
        right: 20px;
        bottom: 20px;
    }
    > div {
        height: 500px;
        height: calc(100vw/2 * .39625);
        overflow: hidden;
        width: calc(50% - 10px);
        position: relative;
    }
    .figure-wrap {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    @media screen and (max-width: 768px) {
        display: block;
        > div {
            width: 100%;
            height: 300px;
        }
        h2 {
            font-size: 18px;
            padding-left: 24px;
        }
    }
`
const LayoutFigure = styled.figure`
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image:url(${(props:object) => props['background-image'] ? inSpaceImg.includes(props['background-image']) ? envAddrInSpace + props['background-image'] : envAddr + props['background-image'] : ""});
    transition: transform .6s cubic-bezier(.77,0,.175,1);
    -webkit-backface-visibility: hidden;
    transform: scale(1);
    &:hover {
        transform: scale(1.2)
    }
`